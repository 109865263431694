import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CDN } from '../../constants/cdnUrls';

const Airplane: FC<{ result: any; matches: boolean }> = ({ result, matches }) => {
    const [fly, setFly] = useState(0);
    const flyEmission = 93.5;
    useEffect(() => {
        if (result.footPrint) {
            setFly(Math.round(result.footPrint / flyEmission));
        }
    }, []);

    return (
        <Grid container item xs={12}>
            <Grid
                item
                xs={12}
                sx={{
                    position: 'relative',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={matches ? CDN.result.airplane.web : CDN.result.airplane.mobile}
                    alt={'airplane'}
                    style={{ width: '100%', height: 'auto' }}
                />
                <Typography
                    variant={'body1'}
                    sx={{
                        position: 'absolute',
                        top: '10%',
                        color: '#CD3232',
                        fontSize: '1em',
                    }}
                >
                    Karbon ayak izin <br /> {fly} kere İstanbul-Ankara arası uçuşa denk!
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Airplane;
