//import { autoPlay } from 'react-swipeable-views-utils';
import * as React from 'react';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import Airplane from '../Airplane';
import MobileAvarage from './MobileAvarage';

const AutoPlaySwipeableViews = SwipeableViews;

const MobileCarousel: FC<{ state: any; result: any; matches: boolean; avarage: number }> = ({
    state,
    result,
    matches,
    avarage,
}) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const components = [
        {
            item: <MobileAvarage result={result} state={state} matches={matches} avarage={avarage} />,
        },
        {
            item: <Airplane result={result} matches={matches} />,
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Box
            sx={{
                width: '100vw',
                height: 'max-content',
                flexGrow: 1,
            }}
        >
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {components.map((step, index) => (
                    <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="div"
                                sx={{
                                    height: 'inherit',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: '100%',
                                }}
                            >
                                {step.item}
                            </Box>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <MobileStepper
                    steps={components.length}
                    position="static"
                    activeStep={activeStep}
                    nextButton={null}
                    backButton={null}
                    sx={{
                        textAlign: 'center',
                    }}
                />
            </Grid>
        </Box>
    );
};

export default MobileCarousel;
