import { TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { Grid, styled } from '@mui/material';
import React, { FC } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

interface IProps {
    hero: any;
    setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>;
    downloadTitle: string;
    shareText: string;
    shareResultDialog?: boolean;
}

const ShareSocialMedia: FC<IProps> = ({
    hero,
    setCloseDialog,
    downloadTitle,
    shareText,
    shareResultDialog = false,
}) => {
    const app_url = 'https://www.modaayakizim.com';

    const radius = 50;
    const size = 48;
    const marginRight = 5;

    const CustomButton = styled('button')(({ theme, color }) => ({
        boxSizing: 'inherit',
        cursor: 'pointer',
        height: size,
        width: size,
        borderRadius: radius,
        border: `1px solid transparent`,
        backgroundColor: color,
        marginRight: marginRight,
    }));

    const downloadHandler = (e: any) => {
        const link = document.createElement('a');
        link.setAttribute('href', hero);
        link.setAttribute('download', downloadTitle);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    return (
        <Grid container xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Grid
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: 'max-content',
                }}
            >
                <CustomButton color={'#dc143c'} onClick={downloadHandler}>
                    <DownloadIcon sx={{ color: '#fff' }} fontSize={'medium'} />
                </CustomButton>
                <TwitterShareButton
                    url={app_url}
                    title={shareResultDialog ? `@nivogo${shareText}` : shareText}
                    style={{ marginRight: marginRight }}
                >
                    <TwitterIcon size={size} borderRadius={radius} />
                </TwitterShareButton>

                <WhatsappShareButton
                    url={app_url}
                    title={shareResultDialog ? `Nivogo${shareText}` : shareText}
                    style={{ marginRight: marginRight }}
                >
                    <WhatsappIcon size={size} borderRadius={radius} />
                </WhatsappShareButton>
                <CustomButton color={'#dadada'} onClick={() => setCloseDialog(false)}>
                    <ClearIcon sx={{ color: '#fff' }} fontSize={'medium'} />
                </CustomButton>
            </Grid>
        </Grid>
    );
};
export default ShareSocialMedia;
