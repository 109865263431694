import { Dialog, DialogContent, Grid } from '@mui/material';
import React, { FC } from 'react';
import ShareSocialMedia from './ShareSocialMedia';
import { AdviceCards } from '../../constants/adviceCards';

interface IDialogProps {
    shareCardDialogOpen: boolean;
    setShareCardDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    hero: {
        id: number;
        png: any;
        title: string;
    };
}

const ShareCardDialog: FC<IDialogProps> = ({ shareCardDialogOpen, setShareCardDialogOpen, hero }) => {
    const shareText = `Günün sürdürülebilirlik ipucu @nivogo : ${AdviceCards[hero?.id - 1]?.content} `;
    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason == 'backdropClick') {
            setShareCardDialogOpen(false);
        }
    };

    return (
        <Dialog
            open={shareCardDialogOpen}
            onClose={handleClose}
            scroll={'body'}
            sx={{
                backdropFilter: 'blur(10px)',
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        minWidth: '200px',
                        maxWidth: '500px',
                        borderRadius: '28px',
                    },
                },
            }}
        >
            <DialogContent sx={{ margin: 0 }}>
                <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} rowSpacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img
                            src={hero.png}
                            alt={'hero'}
                            style={{
                                width: '80%',
                                height: 'auto',
                                padding: 0,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <ShareSocialMedia
                            hero={hero?.png}
                            setCloseDialog={setShareCardDialogOpen}
                            downloadTitle={hero?.title}
                            shareText={shareText}
                            shareResultDialog={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ShareCardDialog;
