import React, { FC } from 'react';
import StepButton from '../components/StepButton';
import Steps from '../components/Steps';
import Question from '../components/Question';
import { Grid, useMediaQuery } from '@mui/material';
import QuestionWrapper from '../components/QuestionWrapper';
import Range from '../components/Range';
import Information from '../components/Information';
import { InformationData } from '../constants/InformationData';
import MobileBottomWrapper from '../components/MobileBottomWrapper';
import { CDN } from '../constants/cdnUrls';

interface IPageProps {
    activeStep: number;

    setActiveStep(i: number): void;

    state: {
        tops: number;
        bottom: number;
        outwear: number;
        shoes: number;
        bags: number;
    };
    setState: React.Dispatch<
        React.SetStateAction<{
            tops: number;
            bottom: number;
            outwear: number;
            shoes: number;
            bags: number;
        }>
    >;

    calculationEvent: {
        event: any;
        step: any;
        yanit: any;
        yuzde_dolap_kullanimi: any;
        alisveris_sikligi: any;
        kategori: any;
        yuzde_iade: any;
        yuzde_ikinci_el_tercih: any;
        yuzde_nivogo_tercih: any;
        camasir_makinesi_kullanim: any;
        yikama_sicaklik: any;
        kurutma_tipi: any;
        kiyafet_kullanim_suresi: any;
        kullanilmayan_giysi_degerlendirme: any;
    };

    setCalculationEvent: React.Dispatch<
        React.SetStateAction<{
            event: any;
            step: any;
            yanit: any;
            yuzde_dolap_kullanimi: string;
            alisveris_sikligi: any;
            kategori: any;
            yuzde_iade: any;
            yuzde_ikinci_el_tercih: any;
            yuzde_nivogo_tercih: any;
            camasir_makinesi_kullanim: any;
            yikama_sicaklik: any;
            kurutma_tipi: any;
            kiyafet_kullanim_suresi: any;
            kullanilmayan_giysi_degerlendirme: any;
        }>
    >;
}

const question = 'Aşağıdaki kategorilerden yılda kaç ürün alıyorsun?';

const Question3: FC<IPageProps> = ({
    activeStep,
    setActiveStep,
    state,
    setState,
    calculationEvent,
    setCalculationEvent,
}) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    return (
        <Grid container sx={{ height: 'calc(100vh - 65px)' }}>
            <Grid item sm={12} md={6} lg={6}>
                <img
                    alt={'white_3'}
                    src={matches ? CDN.question3.web.white : CDN.question3.mobile.white}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                    }
                />
                <img
                    alt={'dark_3'}
                    src={matches ? CDN.question3.web.dark : CDN.question3.mobile.dark}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: (state.bottom + state.outwear + state.bags + state.shoes + state.tops) / 120,
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: (state.bottom + state.outwear + state.bags + state.shoes + state.tops) / 120,
                              }
                    }
                />
                {matches ? <Information content={InformationData['Question3']} /> : null}
            </Grid>
            <Grid
                item
                sm={12}
                md={12}
                lg={6}
                sx={
                    matches
                        ? null
                        : {
                              marginTop: '70%',
                              width: 'calc(100% - 60px)',
                              marginRight: '30px',
                              marginLeft: '30px',
                          }
                }
            >
                <Steps activeStep={activeStep} />
                <QuestionWrapper>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Question text={question} />
                        <Range
                            leftTitle={'Üst Giyim'}
                            rightTitle={`${state.tops} Adet`}
                            min={0}
                            max={30}
                            value={state.tops}
                            onChange={(e) => setState({ ...state, tops: +e.target.value })}
                        />
                        <Range
                            leftTitle={'Alt Giyim'}
                            rightTitle={`${state.bottom} Adet`}
                            min={0}
                            max={30}
                            value={state.bottom}
                            onChange={(e) => setState({ ...state, bottom: +e.target.value })}
                        />
                        <Range
                            leftTitle={'Dış Giyim'}
                            rightTitle={`${state.outwear} Adet`}
                            min={0}
                            max={30}
                            value={state.outwear}
                            onChange={(e) => setState({ ...state, outwear: +e.target.value })}
                        />
                        <Range
                            leftTitle={'Ayakkabı'}
                            rightTitle={`${state.shoes} Adet`}
                            min={0}
                            max={30}
                            value={state.shoes}
                            onChange={(e) => setState({ ...state, shoes: +e.target.value })}
                        />
                        <Range
                            leftTitle={'Çanta'}
                            rightTitle={`${state.bags} Adet`}
                            min={0}
                            max={30}
                            value={state.bags}
                            onChange={(e) => setState({ ...state, bags: +e.target.value })}
                        />
                    </Grid>
                    <StepButton
                        increase={() => {
                            setActiveStep(4);
                            setCalculationEvent({
                                ...calculationEvent,
                                step: 'soru3',
                                kategori: `Üst Giyim ${state.tops} adet, Alt Giyim ${state.bottom} adet, Dış Giyim ${state.outwear} Adet, Ayakkabı ${state.shoes} Adet, Çanta ${state.bags} Adet`,
                                yanit: `Üst Giyim ${state.tops} adet, Alt Giyim ${state.bottom} adet, Dış Giyim ${state.outwear} Adet, Ayakkabı ${state.shoes} Adet, Çanta ${state.bags} Adet`,
                            });
                        }}
                        decrease={() => setActiveStep(2)}
                    />
                </QuestionWrapper>
            </Grid>
            {!matches && <MobileBottomWrapper question={'Question3'} />}
        </Grid>
    );
};

export default Question3;

