import React, { FC } from 'react';
import StepButton from '../components/StepButton';
import Steps from '../components/Steps';
import Question from '../components/Question';
import { Grid, useMediaQuery } from '@mui/material';
import QuestionWrapper from '../components/QuestionWrapper';
import Range from '../components/Range';
import Information from '../components/Information';
import { InformationData } from '../constants/InformationData';
import MobileBottomWrapper from '../components/MobileBottomWrapper';
import { CDN } from '../constants/cdnUrls';

interface IPageProps {
    activeStep: number;

    setActiveStep(i: number): void;

    state: {
        secondHand: number;
        nivogo: number;
        change: boolean;
    };
    setState: React.Dispatch<
        React.SetStateAction<{
            secondHand: number;
            nivogo: number;
            change: boolean;
        }>
    >;

    calculationEvent: {
        event: any;
        step: any;
        yanit: any;
        yuzde_dolap_kullanimi: any;
        alisveris_sikligi: any;
        kategori: any;
        yuzde_iade: any;
        yuzde_ikinci_el_tercih: any;
        yuzde_nivogo_tercih: any;
        camasir_makinesi_kullanim: any;
        yikama_sicaklik: any;
        kurutma_tipi: any;
        kiyafet_kullanim_suresi: any;
        kullanilmayan_giysi_degerlendirme: any;
    };

    setCalculationEvent: React.Dispatch<
        React.SetStateAction<{
            event: any;
            step: any;
            yanit: any;
            yuzde_dolap_kullanimi: string;
            alisveris_sikligi: any;
            kategori: any;
            yuzde_iade: any;
            yuzde_ikinci_el_tercih: any;
            yuzde_nivogo_tercih: any;
            camasir_makinesi_kullanim: any;
            yikama_sicaklik: any;
            kurutma_tipi: any;
            kiyafet_kullanim_suresi: any;
            kullanilmayan_giysi_degerlendirme: any;
        }>
    >;
}

const questionMain = 'Satın aldığın ürünlerin yüzde kaçını:';
const question1 = 'İkinci el tercih ediyorsun?';
const question2 = 'Nivogo’dan tercih ediyorsun?';
const question2Sub =
    '(Nivogo, Dünyaca ünlü markaların farklı sebeplerle atıl durumda kalmış ürünlerini\n' +
    'yeniler, yeniden fiyatlandırır ve seninle buluşturur.)';

const Question5: FC<IPageProps> = ({
    activeStep,
    setActiveStep,
    state,
    setState,
    calculationEvent,
    setCalculationEvent,
}) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    return (
        <Grid container sx={{ height: 'calc(100vh - 65px)' }}>
            <Grid item sm={12} md={6} lg={6}>
                <div>
                    <img
                        alt={'white_5'}
                        src={
                            state.nivogo > 0
                                ? matches
                                    ? CDN.question5.web.withLogo
                                    : CDN.question5.mobile.withLogoWithoutCircle
                                : matches
                                ? CDN.question5.web.withoutLogo
                                : CDN.question5.mobile.withoutLogoWithoutCircle
                        }
                        style={
                            matches
                                ? {
                                      height: 'calc(100vh - 65px)',
                                      width: 'auto',
                                      position: 'absolute',
                                      left: 0,
                                      right: 0,
                                      zIndex: '-99',
                                  }
                                : {
                                      width: '100%',
                                      height: 'auto',
                                      position: 'absolute',
                                      left: 0,
                                      top: '65px',
                                      zIndex: '-99',
                                  }
                        }
                    />

                    <img
                        alt={'circle_5'}
                        src={matches ? CDN.question5.web.circle : CDN.question5.mobile.circle}
                        style={
                            matches
                                ? {
                                      height: '56%',
                                      width: 'auto',
                                      position: 'absolute',
                                      top: '19vh',
                                      left: '10vh',
                                      zIndex: '-99',
                                      animation: `circleFrame ${
                                          100 / (state.secondHand + state.nivogo)
                                      }s linear infinite`,
                                  }
                                : {
                                      height: '50vw',
                                      width: 'auto',
                                      position: 'absolute',
                                      top: 'calc(65px + 14vw)',
                                      left: '22vw',
                                      zIndex: '-99',
                                      animation: `circleFrame ${
                                          100 / (state.secondHand + state.nivogo)
                                      }s linear infinite`,
                                  }
                        }
                    />
                </div>
                <style>
                    {`
                     @keyframes circleFrame {
                        0% {
                          transform: rotate(0deg);
                        }
                        100% {
                          transform: rotate(360deg);
                        }
                      } 
                    `}
                </style>

                {matches ? <Information content={InformationData['Question5']} /> : null}
            </Grid>
            <Grid
                item
                sm={12}
                md={12}
                lg={6}
                sx={
                    matches
                        ? null
                        : {
                              marginTop: '70%',
                              width: 'calc(100% - 60px)',
                              marginRight: '30px',
                              marginLeft: '30px',
                          }
                }
            >
                <Steps activeStep={activeStep} />

                <QuestionWrapper>
                    <Question text={questionMain} />
                    <Question text={question1} variant={'subtitle1'} />
                    <Range
                        rightTitle={`%${state.secondHand}`}
                        min={0}
                        max={100}
                        value={state.secondHand}
                        onChange={(e) => {
                            setState({
                                ...state,
                                secondHand: +e.target.value + state.nivogo <= 100 ? +e.target.value : state.secondHand,
                                change: true,
                            });
                        }}
                    />
                    <Question text={question2} variant={'subtitle1'} />
                    <Question text={question2Sub} variant={'subtitle2'} />
                    <Range
                        rightTitle={`%${state.nivogo}`}
                        min={0}
                        max={100}
                        value={state.nivogo}
                        onChange={(e) =>
                            setState({
                                ...state,
                                nivogo: state.secondHand + +e.target.value <= 100 ? +e.target.value : state.nivogo,
                            })
                        }
                    />

                    <StepButton
                        increase={() => {
                            setActiveStep(6);
                            setCalculationEvent({
                                ...calculationEvent,
                                step: 'soru5',
                                yuzde_ikinci_el_tercih: `%${state.secondHand}`,
                                yuzde_nivogo_tercih: `%${state.nivogo}`,
                                yanit: `%${state.secondHand} ,%${state.nivogo}`,
                            });
                        }}
                        decrease={() => setActiveStep(4)}
                    />
                </QuestionWrapper>
            </Grid>
            {!matches && <MobileBottomWrapper question={'Question5'} />}
        </Grid>
    );
};

export default Question5;

