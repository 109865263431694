import { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import WebLanding from './Landing/WebLanding';
import MobileLanding from './Landing/MobileLanding';

interface IPageProps {
    setActiveStep(i: number): void;
}

const Landing: FC<IPageProps> = ({ setActiveStep }) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    return matches ? <WebLanding setActiveStep={setActiveStep} /> : <MobileLanding setActiveStep={setActiveStep} />;
};

export default Landing;
