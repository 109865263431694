const CDN_URL = 'https://d3kzzedcczirs.cloudfront.net/nivogo/img/';

const url = (image: string, extension = '.png'): string => {
    return CDN_URL + image + extension;
};

export const CDN = {
    landing: {
        text: url('landing_text'),
        web: url('landing_web'),
        mobile: url('landing_mobile'),
    },
    question1: {
        web: {
            white: url('1_web_white'),
            dark: url('1_web_dark'),
        },
        mobile: {
            white: url('1_mobile_white'),
            dark: url('1_mobile_dark'),
        },
    },
    question2: {
        web: {
            white: url('2_web_white'),
            dark: url('2_web_dark'),
        },
        mobile: {
            white: url('2_mobile_white'),
            dark: url('2_mobile_dark'),
        },
    },
    question3: {
        web: {
            white: url('3_web_white'),
            dark: url('3_web_dark'),
        },
        mobile: {
            white: url('3_mobile_white'),
            dark: url('3_mobile_dark'),
        },
    },
    question4: {
        web: {
            white: url('4_web_white'),
            dark: url('4_web_dark'),
        },
        mobile: {
            white: url('4_mobile_white'),
            dark: url('4_mobile_dark'),
        },
    },
    question5: {
        web: {
            circle: url('5_web_circle'),
            withLogo: url('5_web_with_logo'),
            withoutLogo: url('5_web_without_logo'),
        },
        mobile: {
            circle: url('5_mobile_circle'),
            withLogoWithoutCircle: url('5_mobile_with_logo_without_circle'),
            withLogo: url('5_mobile_with_logo'),
            withoutLogoWithoutCircle: url('5_mobile_without_logo_without_circle'),
        },
    },
    question6: {
        web: {
            white: url('6_web_white'),
            dark: url('6_web_dark'),
        },
        mobile: {
            white: url('6_mobile_white'),
            dark: url('6_mobile_dark'),
        },
    },
    question7: {
        web: {
            white: url('7_web_white'),
            dark: url('7_web_dark'),
        },
        mobile: {
            white: url('7_mobile_white'),
            dark: url('7_mobile_dark'),
        },
    },
    question8: {
        web: {
            white: url('8_web_white'),
            dark: url('8_web_dark'),
        },
        mobile: {
            white: url('8_mobile_white'),
            dark: url('8_mobile_dark'),
        },
    },
    question9: {
        web: {
            white: url('9_web_white'),
            dark: url('9_web_dark'),
        },
        mobile: {
            white: url('9_mobile_white'),
            dark: url('9_mobile_dark'),
        },
    },
    result: {
        airplane: {
            web: url('result_airplane_web', '.jpg'),
            mobile: url('result_airplane_mobile', '.jpg'),
        },
        cards: {
            card1: url('result_cards_card1'),
            card2: url('result_cards_card2'),
            card3: url('result_cards_card3'),
            card4: url('result_cards_card4'),
            card5: url('result_cards_card5'),
            card6: url('result_cards_card6'),
            card7: url('result_cards_card7'),
            card8: url('result_cards_card8'),
            card9: url('result_cards_card9'),
            card10: url('result_cards_card10'),
        },
        emojis: {
            emoji1: url('result_emojis_emoji1'),
            emoji2: url('result_emojis_emoji2'),
            emoji3: url('result_emojis_emoji3'),
            emoji4: url('result_emojis_emoji4'),
            emoji5: url('result_emojis_emoji5'),
            emoji6: url('result_emojis_emoji6'),
        },
        share: {
            web: {
                low: url('result_share_web_low'),
                lowMedium: url('result_share_web_low_medium'),
                medium: url('result_share_web_medium'),
                mediumHigh: url('result_share_web_medium_high'),
                high: url('result_share_web_high'),
            },
            mobile: {
                low: url('result_share_mobile_low'),
                lowMedium: url('result_share_mobile_low_medium'),
                medium: url('result_share_mobile_medium'),
                mediumHigh: url('result_share_mobile_medium_high'),
                high: url('result_share_mobile_high'),
            },
        },
        sharedCards: {
            hero1: url('result_shared_cards_hero1'),
            hero1Title: url('result_shared_cards_hero1_title'),
            hero2: url('result_shared_cards_hero2'),
            hero2Title: url('result_shared_cards_hero2_title'),
            hero3: url('result_shared_cards_hero3'),
            hero3Title: url('result_shared_cards_hero3_title'),
            hero4: url('result_shared_cards_hero4'),
            hero4Title: url('result_shared_cards_hero4_title'),
            hero5: url('result_shared_cards_hero5'),
            hero5Title: url('result_shared_cards_hero5_title'),
            leftArrow: url('result_shared_cards_left_arrow', '.svg'),
            rightArrow: url('result_shared_cards_right_arrow', '.svg'),
        },
        low: url('result_low'),
        lowMedium: url('result_low_medium'),
        medium: url('result_medium'),
        mediumHigh: url('result_medium_high'),
        high: url('result_high'),
        footerLogo: url('result_footer_logo'),
        logoIcon: url('result_logo_icon'),
        sliderFoot: url('result_slider_foot', '.svg'),
    },
};
