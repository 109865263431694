import { Slider, styled, Typography, Grid, useMediaQuery } from '@mui/material';
import { FC } from 'react';

interface IRangeProps {
    leftTitle?: string;
    rightTitle?: string;
    min: number;
    max: number;
    value: number;
    onChange?: (e?: any) => void;
    onClick?: (e?: any) => {};
}

const RangeWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
}));

const Range: FC<IRangeProps> = ({ leftTitle, rightTitle, min, max, value, onClick, onChange }) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    const customSize = () => {
        if (!matches) {
            if (leftTitle && rightTitle) return 7;
            if (leftTitle || rightTitle) return 9.5;
            return 12;
        } else {
            if (leftTitle && rightTitle) return 7;
            if (leftTitle || rightTitle) return 7;
            return 7;
        }
    };

    return (
        <Grid container alignItems={'center'} marginBottom={'20px'}>
            {leftTitle ? (
                <Grid item xs={2.5}>
                    <Typography variant={'subtitle1'} sx={{ textAlign: 'start' }}>
                        {leftTitle}
                    </Typography>
                </Grid>
            ) : (
                matches && (
                    <Grid item xs={2.5}>
                        <Typography variant={'subtitle1'} sx={{ textAlign: 'start' }}>
                            {leftTitle}
                        </Typography>
                    </Grid>
                )
            )}

            <Grid
                item
                xs={customSize()}
                sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: !matches && {
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    },
                })}
            >
                <Slider
                    sx={{
                        '& .MuiSlider-thumb': {
                            appearance: 'none',
                            cursor: 'ew-resize',
                            height: '3em',
                            width: '3em',
                            marginLeft: '6px',
                            marginRight: '6px',
                            background: '#E3AE4B',
                            borderRadius: '50%',
                            boxShadow: '-4px 4px 2px 0px #707070',
                            '&:focus, &:hover, &.Mui-active': {
                                boxShadow: '-4px 4px 2px 0px #707070',
                            },
                        },
                        '& .MuiSlider-track': {
                            height: '1.625em',
                            borderRadius: '19px',
                            backgroundColor: '#E3AE4B',
                            boxShadow: '-4px 4px 3px 0px #707070',
                            border: 'none',
                        },
                        '& .MuiSlider-rail': {
                            height: '1.625em',
                            borderRadius: '19px',
                            backgroundColor: '#BFEDD7',
                            boxShadow: '-4px 4px 3px 0px #707070',
                            opacity: 1,
                        },
                    }}
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                />
            </Grid>
            {rightTitle ? (
                <Grid item xs={2.5}>
                    <Typography variant={'subtitle1'}>{rightTitle}</Typography>
                </Grid>
            ) : (
                matches && (
                    <Grid item xs={2.5}>
                        <Typography variant={'subtitle1'}>{rightTitle}</Typography>
                    </Grid>
                )
            )}
        </Grid>
    );
};

export default Range;
