import { Grid, styled, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { CDN } from '../../constants/cdnUrls';
import { BASE_URL } from '../../api/result';

const emojisContent = [
    {
        id: 1,
        icon: CDN.result.emojis.emoji1,
        type: 'rating1Percentage',
        backgroundColor: '#5691E1',
        border: 'rgba(86,145,225,0.5)',
    },
    {
        id: 2,
        icon: CDN.result.emojis.emoji2,
        type: 'rating2Percentage',
        backgroundColor: '#E3AE4B',
        border: 'rgba(227,174,75,0.5)',
    },
    {
        id: 3,
        icon: CDN.result.emojis.emoji3,
        type: 'rating3Percentage',
        backgroundColor: '#B1D184',
        border: 'rgba(177,209,132,0.5)',
    },
    {
        id: 4,
        icon: CDN.result.emojis.emoji4,
        type: 'rating4Percentage',
        backgroundColor: '#FAE378',
        border: 'rgba(250,227,120,0.5)',
    },
    {
        id: 5,
        icon: CDN.result.emojis.emoji5,
        type: 'rating5Percentage',
        backgroundColor: '#CD3232',
        border: 'rgba(205,50,50,0.5)',
    },
    {
        id: 6,
        icon: CDN.result.emojis.emoji6,
        type: 'rating6Percentage',
        backgroundColor: '#FF927E',
        border: 'rgba(255,146,126,0.5)',
    },
];

const EmojiButton = styled('button')<{ matches: boolean }>(({ matches, theme }) => ({
    cursor: 'pointer',
    margin: '1.875em',
    color: theme.palette.success.main,
    backgroundColor: '#E3AE4B',
    width: '7em',
    border: '1px solid #707070',
    borderRadius: '0.813em',
    fontWeight: 'medium',
    transition: '0.3s',
}));

const Emojis: FC<{ result: any; surveyId: string; matches: boolean }> = ({ result, surveyId, matches }) => {
    const [emojisData, setEmojisData] = useState({
        totalSurveyCounter: 0,
        totalRatingCounter: 0,
        rating1Counter: 0,
        rating1Percentage: 0,
        rating2Counter: 0,
        rating2Percentage: 0,
        rating3Counter: 0,
        rating3Percentage: 0,
        rating4Counter: 0,
        rating4Percentage: 0,
        rating5Counter: 0,
        rating5Percentage: 0,
        rating6Counter: 0,
        rating6Percentage: 0,
    });

    const ratingHandler = async (id: any) => {
        if (id) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'access-control-allow-origin': '*',
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({
                    surveyId: surveyId,
                    rating: id,
                }),
            };

            return await fetch(BASE_URL, requestOptions).then(async () => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'access-control-allow-origin': '*',
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                };

                return await fetch(BASE_URL, requestOptions)
                    .then((response) => response.json())
                    .then((data) => setEmojisData(data?.emissionSurveyRatingDto))
                    .catch((err) => console.log(err));
            });
        }
    };

    return (
        <Grid
            container
            item
            xs={12}
            style={{ minHeight: '300px', height: 'max-content', marginBottom: '30px' }}
            alignItems={'end'}
            justifyContent={'center'}
            display={'flex'}
        >
            <Grid item xs={12}>
                <Typography variant={'body1'}>Sonucunu öğrendin nasıl hissediyorsun?</Typography>
                <Typography variant={'subtitle2'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 27.968 29.168">
                        <path
                            id="d0d5f28d31ab8c55d819e0dc5a3fb1c2"
                            d="M18.379,9.448V5.61a1.219,1.219,0,1,0-2.437,0V9.448a1.219,1.219,0,1,0,2.437,0ZM9.851,13.811a1.2,1.2,0,0,1-.865-.363L6.318,10.736a1.274,1.274,0,0,1,0-1.763,1.2,1.2,0,0,1,1.718,0l2.644,2.713a1.274,1.274,0,0,1,.257,1.351,1.219,1.219,0,0,1-1.109.774ZM25.36,26.738,28,29.451a1.273,1.273,0,0,1,0,1.775,1.2,1.2,0,0,1-1.73,0L23.63,28.514a1.279,1.279,0,0,1,0-1.775,1.2,1.2,0,0,1,1.73,0Zm.962-6.576a1.235,1.235,0,0,1,1.218-1.25h3.728a1.251,1.251,0,0,1,0,2.5H27.541A1.235,1.235,0,0,1,26.323,20.162Zm0-11.127a1.2,1.2,0,0,1,1.484.181,1.275,1.275,0,0,1,.2,1.52L25.36,13.449a1.213,1.213,0,0,1-1.73,0,1.273,1.273,0,0,1,0-1.763ZM4.917,33.164a1.2,1.2,0,0,0,1.718,0l4.423-4.538L14.2,32.814a1.208,1.208,0,0,0,.962.488h.207a1.229,1.229,0,0,0,.95-.85L20.6,18.537a1.273,1.273,0,0,0-.3-1.27,1.2,1.2,0,0,0-1.238-.305L5.575,21.413a1.24,1.24,0,0,0-.838.976A1.267,1.267,0,0,0,5.2,23.6l4.081,3.225-4.41,4.526a1.274,1.274,0,0,0,0,1.75Zm7.176-7.251-.085-.075-.085-.075L8.584,23.125l9.028-2.963-2.887,9.251Z"
                            transform="translate(-4.52 -4.36)"
                            fill="#00675a"
                        />
                    </svg>
                    Tıkla ve oy ver
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'center',
                }}
            >
                {emojisContent.map((emoji) => (
                    <EmojiButton
                        matches={matches}
                        key={emoji.id}
                        sx={{
                            height: `calc(${emojisData[emoji.type as keyof typeof emojisData] * 0.2}em + 7em)`,
                            border: emoji.backgroundColor,
                            backgroundColor: emoji.backgroundColor,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                border: `0.25em solid ${emoji.border}`,
                                fontWeight: 'normal',
                                boxShadow: 'none',
                                transition: '.3s',
                            },
                        }}
                        onClick={() => ratingHandler(emoji.id)}
                    >
                        <img src={emoji.icon} alt={emoji.type} width={'80%'} height={'auto'} />
                    </EmojiButton>
                ))}
            </Grid>
        </Grid>
    );
};

export default Emojis;
