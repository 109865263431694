import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

const shadowArray = [
    'none',
    'rgb(113 122 131 / 11%) 0px 7px 30px 0px',
    'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
    ...Array(22).fill('rgb(90 114 123 / 11%) 0px 7px 30px 0px'),
] as Shadows;

const baseTheme = createTheme({
    palette: {
        primary: {
            main: '#03c9d7',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#fb9678',
            contrastText: '#ffffff',
        },
        background: {
            default: '#fff',
            paper: '#ffffff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgb(119, 126, 137)',
        },
        error: {
            main: '#e46a76',
        },
        warning: {
            main: '#fec90f',
            contrastText: '#ffffff',
        },
        success: {
            main: '#00675A',
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
            fontSize: '1.875em',
            lineHeight: 1.5,
            fontWeight: 500,
            letterSpacing: '0em',
        },
        h2: {
            fontSize: '1.3em',
            lineHeight: 1.5,
            fontWeight: 500,
            letterSpacing: '0em',
        },
        h3: {
            fontSize: '1.15em',
            fontWeight: 500,
            lineHeight: 1.5,
        },
        h4: {
            fontSize: '1.125em',
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: '0em',
        },
        h5: {
            fontSize: '1.55em',
            fontWeight: 550,
            lineHeight: 1.75,
            letterSpacing: '0em',
            color: '#2B655A',
            textAlign: 'center',
        },
        h6: {
            fontSize: '1.35em',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0em',
            color: '#2B655A',
        },
        subtitle1: {
            // Inputs
            fontSize: '1.15em',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0em',
            color: '#2B655A',
        },
        subtitle2: {
            // Footer
            fontSize: '1em',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0em',
        },
        body1: {
            // Question
            fontSize: '1.75em',
            fontWeight: 550,
            letterSpacing: '0em',
            color: '#2B655A',
            textAlign: 'center',
        },
        body2: {
            // Landing text
            fontSize: '1.25em',
            fontWeight: 450,
            letterSpacing: '0em',
        },
        caption: {
            // Question 7
            fontSize: '1.2em',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0em',
            color: '#2B655A',
        },
        overline: {
            fontSize: '0.75em',
            lineHeight: 2.6,
            letterSpacing: '0em',
        },
        button: {
            // Landing-Step Button
            fontSize: '1.5rem',
            lineHeight: 1,
            fontWeight: 400,
            letterSpacing: '0em',
            textTransform: 'none',
        },
    },
    shape: {
        borderRadius: 5,
    },
    shadows: shadowArray,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

let theme = createTheme(
    {
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'none',
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        color: baseTheme.palette.text.secondary,
                        backgroundColor: baseTheme.palette.primary.contrastText,
                        borderColor: 'rgb(224, 224, 224)',
                        ...baseTheme.typography.h5,
                    },
                },
            },
        },
    },
    baseTheme
);

theme = responsiveFontSizes(theme);

export default theme;
