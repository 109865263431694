import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

interface IRadioProps {
    id: string;
    text: string;
    name: string;
    value?: string | number;
    onChange?: (e?: any) => void;
    onClick?: (e?: any) => void;
    defaultChecked?: boolean;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

const SelectionWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '3.5em 1fr',
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '8vw 1fr',
    },
    alignItems: 'center',
    justifyContent: 'start',
    margin: '10px',
}));
const RadioWrapper = styled('input')(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#fff',
        boxShadow: 'none',
    },
    '&:checked': {
        backgroundColor: theme.palette.success.main,
        boxShadow: 'none',
    },
    cursor: 'pointer',
    appearance: 'none',
    height: '8vw',
    width: '8vw',
    maxHeight: '3em',
    maxWidth: '3em',
    borderRadius: '2.5em',
    border: `1.5px solid ${theme.palette.success.main}`,
    boxShadow: '-4px 4px 2px 0px #aaa',
    transition: '.3s',
}));
const LabelWrapper = styled('label')(({ theme }) => ({
    marginLeft: '0.625em',
    textAlign: 'start',
}));

const RadioInput: FC<IRadioProps> = ({
    id,
    name,
    value,
    variant = 'subtitle1',
    text,
    onChange,
    onClick,
    defaultChecked = false,
}) => {
    return (
        <SelectionWrapper>
            <RadioWrapper
                type={'radio'}
                name={name}
                id={id}
                value={value}
                onClick={onClick}
                onChange={onChange}
                defaultChecked={defaultChecked}
            />
            <LabelWrapper htmlFor={id}>
                <Typography variant={variant}>{text}</Typography>
            </LabelWrapper>
        </SelectionWrapper>
    );
};

export default RadioInput;
