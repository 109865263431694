import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

const MobileAvarage: FC<{ result: any; state: any; matches: boolean; avarage: number }> = ({
    result,
    state,
    matches,
    avarage,
}) => {
    const Status = {
        ['LOW']: {
            backgroundColor: '#00675A',
            circleColor: '#B1D184',
            value: 40,
        },
        ['LOW_MEDIUM']: {
            backgroundColor: '#00675A',
            circleColor: '#B1D184',
            value: 60,
        },
        ['MEDIUM']: {
            backgroundColor: '#E3AE4B',
            circleColor: '#FAE378',
            value: 80,
        },
        ['MEDIUM_HIGH']: {
            backgroundColor: '#CD3232',
            circleColor: '#FF927E',
            value: 100,
        },
        ['HIGH']: {
            backgroundColor: '#CD3232',
            circleColor: '#FF927E',
            value: 120,
        },
    };

    const checkStatus = () => {
        if (state === 'LOW') return true;
        if (state === 'LOW_MEDIUM') return true;
        return false;
    };

    const Bar: FC<{ value: number; state: any }> = ({ value, state }) => {
        return (
            <Grid
                item
                xs={12}
                sx={{
                    alignItems: 'end',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                }}
            >
                <div style={{ textAlign: 'center', marginRight: '20px' }}>
                    <div
                        style={{
                            borderRadius: '10px',
                            minHeight: '50px',
                            height: `${Status[state as keyof typeof Status].value}px`,
                            width: '60px',
                            backgroundColor: '#FFFFFF',
                            textAlign: 'center',
                        }}
                    ></div>
                    <Typography variant={'inherit'}>Sen:</Typography>
                    <Typography variant={'inherit'}>
                        <b>{isNaN(value) ? 0 : Math.ceil(value)} kg</b>
                    </Typography>
                </div>
                <div>
                    <div
                        style={{
                            borderRadius: '10px',
                            height: '80px',
                            width: '60px',
                            backgroundColor: Status[state as keyof typeof Status]?.circleColor,
                        }}
                    ></div>
                    <Typography variant={'inherit'}>Ortalama:</Typography>
                    <Typography variant={'inherit'}>
                        <b>{isNaN(avarage) ? 0 : avarage} kg</b>
                    </Typography>
                </div>
            </Grid>
        );
    };

    const calculate = () => {
        const inc = result?.footPrint - avarage;
        const temp = (inc / avarage) * 100;
        const res = Math.ceil(Math.abs(temp));
        return isNaN(res) ? 0 : res;
    };

    return (
        <Grid
            item
            container
            xs={12}
            style={{
                height: '61.3vw',
                backgroundColor: Status[state as keyof typeof Status]?.backgroundColor,
                color: '#FFF',
            }}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
        >
            <Grid item xs={12} mt={1}>
                {checkStatus() && (
                    <Typography variant={'body1'} color={'#FFF'}>
                        Tebrikler!
                    </Typography>
                )}
                <Typography variant={'body2'} color={'#FFF'}>
                    Moda ayak izin, ortalamanın
                </Typography>
                <Typography
                    variant={'body2'}
                    sx={{
                        margin: 'auto',
                        width: '170px',
                        backgroundColor: '#fff',
                        color: Status[state as keyof typeof Status]?.backgroundColor,
                    }}
                >
                    %{calculate()} {result?.footPrint >= avarage ? 'üstünde!' : 'altında!'}
                </Typography>
            </Grid>

            <Grid item xs={12} mt={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Grid xs={6}>
                    <Bar value={result.footPrint} state={state} />
                </Grid>

                <Grid xs={6}>
                    <Typography variant={'subtitle2'}>Moda alışkanlıklarının</Typography>
                    <Typography variant={'subtitle2'}>1 yılda oluşturduğu</Typography>
                    <Typography variant={'subtitle2'}>
                        karbon emisyonu {isNaN(result?.footPrint) ? 0 : Math.ceil(result?.footPrint)} kg
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default MobileAvarage;
