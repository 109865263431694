import Navbar from './components/Navbar';
import { useEffect, useState } from 'react';
import Landing from './pages/Landing';
import Question1 from './pages/Question1';
import Question2 from './pages/Question2';
import Question3 from './pages/Question3';
import Question4 from './pages/Question4';
import Question5 from './pages/Question5';
import Question6 from './pages/Question6';
import Question7 from './pages/Question7';
import Question8 from './pages/Question8';
import Question9 from './pages/Question9';
import Result from './pages/Result';
import { styled } from '@mui/material';
import TagManager from 'react-gtm-module';

const Container = styled('div')(({ theme }) => ({
    root: {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
    },
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
    },
}));

export interface IResult {
    C1: number;
    C2: number;
    C3: number;
    C4: number;
    C5: number;
    C6: number;
    C7: number;
    C8: number;
    C9: number;
    C10: number;
    C11: number;
    W: number;
    footPrint: number;
}

const App = () => {
    const [surveyId, setSurveyId] = useState<string>('');
    const [result, setResult] = useState<IResult>({} as IResult);
    const [activeStep, setActiveStep] = useState(0);
    const [calculationEvent, setCalculationEvent] = useState({
        event: 'hesaplama',
        step: 'soru1',
        yanit: '',
        yuzde_dolap_kullanimi: '',
        alisveris_sikligi: '',
        kategori: '',
        yuzde_iade: '',
        yuzde_ikinci_el_tercih: '',
        yuzde_nivogo_tercih: '',
        camasir_makinesi_kullanim: '',
        yikama_sicaklik: '',
        kurutma_tipi: '',
        kiyafet_kullanim_suresi: '',
        kullanilmayan_giysi_degerlendirme: '',
    });

    const [calculationResult, setCalculationResult] = useState({
        event: 'hesaplama_sonuc',
        deger: '',
        karbon_emisyonu: '',
    });

    const [question1State, setQuestion1State] = useState({
        use: 0,
    });
    const [question2State, setQuestion2State] = useState({
        time: 0,
        parameter: 1,
    });
    const [question3State, setQuestion3State] = useState({
        tops: 0,
        bottom: 0,
        outwear: 0,
        shoes: 0,
        bags: 0,
    });
    const [question4State, setQuestion4State] = useState({
        shopping: 0,
    });
    const [question5State, setQuestion5State] = useState({
        secondHand: 0,
        nivogo: 0,
        change: false,
    });
    const [question6State, setQuestion6State] = useState({
        washingMachine: 0,
    });
    const [question7State, setQuestion7State] = useState({
        washTemp: 100,
        howDry: 0,
    });
    const [question8State, setQuestion8State] = useState({
        lifetime: 0,
    });
    const [question9State, setQuestion9State] = useState({
        whatDo: 0,
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [activeStep]);

    useEffect(() => {
        if (calculationEvent.yuzde_dolap_kullanimi != '') {
            TagManager.initialize({
                gtmId: 'GTM-5XG7MGG5',
                dataLayer: calculationEvent,
            });
        }
    }, [calculationEvent]);

    useEffect(() => {
        if (calculationResult.deger != '') {
            TagManager.initialize({
                gtmId: 'GTM-5XG7MGG5',
                dataLayer: calculationResult,
            });
        }
    }, [calculationResult]);

    return (
        <Container>
            <Navbar />
            {activeStep === 0 ? <Landing setActiveStep={setActiveStep} /> : null}
            {activeStep === 1 ? (
                <Question1
                    setActiveStep={setActiveStep}
                    state={question1State}
                    setState={setQuestion1State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 2 ? (
                <Question2
                    setActiveStep={setActiveStep}
                    state={question2State}
                    setState={setQuestion2State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 3 ? (
                <Question3
                    setActiveStep={setActiveStep}
                    state={question3State}
                    setState={setQuestion3State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 4 ? (
                <Question4
                    setActiveStep={setActiveStep}
                    state={question4State}
                    setState={setQuestion4State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 5 ? (
                <Question5
                    setActiveStep={setActiveStep}
                    state={question5State}
                    setState={setQuestion5State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 6 ? (
                <Question6
                    setActiveStep={setActiveStep}
                    state={question6State}
                    setState={setQuestion6State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 7 ? (
                <Question7
                    setActiveStep={setActiveStep}
                    state={question7State}
                    setState={setQuestion7State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 8 ? (
                <Question8
                    setActiveStep={setActiveStep}
                    state={question8State}
                    setState={setQuestion8State}
                    activeStep={activeStep}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 9 ? (
                <Question9
                    setActiveStep={setActiveStep}
                    state={question9State}
                    setState={setQuestion9State}
                    activeStep={activeStep}
                    setSurveyId={setSurveyId}
                    setResult={setResult}
                    global={{
                        question1State,
                        question2State,
                        question3State,
                        question4State,
                        question5State,
                        question6State,
                        question7State,
                        question8State,
                        question9State,
                    }}
                    setCalculationEvent={setCalculationEvent}
                    calculationEvent={calculationEvent}
                />
            ) : null}
            {activeStep === 10 ? (
                <Result
                    surveyId={surveyId}
                    result={result}
                    setCalculationResult={setCalculationResult}
                    calculationResult={calculationResult}
                />
            ) : null}
        </Container>
    );
};

export default App;

