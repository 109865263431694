import { Button, styled, Typography } from '@mui/material';
import { FC, useTransition } from 'react';

interface INavbarProps {}

const NavbarWrapper = styled('nav')(({ theme }) => ({
    left: 0,
    top: 0,
    boxSizing: 'border-box',
    width: '100%',
    height: '65px',
    padding: '15px 30px',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
    },
}));
const LogoWrapper = styled('div')(({ theme }) => ({
    width: '193px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
}));

const TitleWrapper = styled('p')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        display: 'none',
    },
}));

const Navbar: FC<INavbarProps> = () => {
    return (
        <NavbarWrapper>
            <LogoWrapper onClick={() => (window.location.href = '/')}>
                <svg
                    id="Group_1"
                    data-name="Group 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40.481"
                    height="43.224"
                    viewBox="0 0 40.481 43.224"
                >
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M55.525,350.471a19.787,19.787,0,0,0-19.9-17.89V328.6a21.685,21.685,0,0,0-20.48,21.871H34.945a2.243,2.243,0,0,1-2.318,3.709,1.943,1.943,0,0,0-2.449,2.983c5.019,5.352,14.463.595,13.052-6.7h6.763c2.338,16.64-21.891,22.475-27.525,6.9l2.9.242c-1.693-1.028-5.09-3.094-6.773-4.112-.857,1.562-2.651,4.787-3.5,6.329l1.965-1.2C25.39,379.749,57.087,374.417,55.525,350.471ZM29.674,345.24a2.731,2.731,0,0,1,0-5.463A2.731,2.731,0,0,1,29.674,345.24Z"
                        transform="translate(-15.1 -328.6)"
                        fill="#fff"
                    />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="141.332" height="39.065" viewBox="0 0 141.332 39.065">
                    <g id="Group_2" data-name="Group 2" transform="translate(0 0)">
                        <path
                            id="Path_2"
                            data-name="Path 2"
                            d="M507.887,445.609c0-2.106-.685-3.155-2.187-3.235v-1.008l8.627-.564a12.024,12.024,0,0,1,1.139,3.85,10.686,10.686,0,0,1,8.063-4.052c4.334.161,5.422,2.429,5.422,6.884V456.8c0,2.147.726,3.195,2.227,3.276v1.008H519.064v-1.008c1.5-.081,2.308-1.139,2.308-3.276v-7.781c-.04-1.381.2-4.052-1.9-4.052a3.762,3.762,0,0,0-3.276,2.227,11.134,11.134,0,0,0-.726,4.455v5.14c0,2.147.927,3.195,2.429,3.276v1.008H505.7v-1.008c1.5-.081,2.187-1.139,2.187-3.276Z"
                            transform="translate(-505.7 -431.398)"
                            fill="#fff"
                        />
                        <path
                            id="Path_3"
                            data-name="Path 3"
                            d="M773.487,363.511c0-2.106-.685-3.155-2.187-3.235v-1.008l9.716-.564V374.7c0,2.147.766,3.195,2.268,3.276v1.008H771.3v-1.008c1.5-.081,2.187-1.139,2.187-3.276Zm-.454-10.562c0-2.348,2.147-3.648,4.293-3.648a3.683,3.683,0,0,1,3.931,3.769c0,2.187-2.106,3.527-4.172,3.527C774.938,356.587,773.034,355.256,773.034,352.948Z"
                            transform="translate(-744.531 -349.3)"
                            fill="#fff"
                        />
                        <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M910.007,450.065a5.852,5.852,0,0,0,.564-2.187c0-.806-.6-1.179-1.381-1.26V445.61h6.843v1.008c-1.985.363-2.751,3.034-3.407,4.656l-3.971,9.877a10.156,10.156,0,0,0-.766,2.469c0,.564.363.806.887.927v1.008h-9.232v-1.008a.932.932,0,0,0,.968-.968,10.306,10.306,0,0,0-.484-1.5L895.493,449.8c-.524-1.461-1.088-3.155-2.993-3.195V445.6h12.316v1.008c-.806.04-1.7.322-1.7,1.26a8.8,8.8,0,0,0,.443,1.986l2.872,9.313Z"
                            transform="translate(-853.516 -435.894)"
                            fill="#fff"
                        />
                        <path
                            id="Path_5"
                            data-name="Path 5"
                            d="M1133.11,459.417a9.318,9.318,0,0,1-3.81-7.942c0-7.126,5.876-10.976,12.437-10.976,6.48,0,11.782,3.528,11.782,10.573,0,6.964-5.835,10.451-12.145,10.451A15.579,15.579,0,0,1,1133.11,459.417Zm12.306-7.781c0-3.034-.766-8.063-4.132-8.063-3.275,0-3.89,4.173-3.89,6.642,0,2.671.806,8.224,4.415,8.184C1144.891,458.359,1145.416,453.461,1145.416,451.637Z"
                            transform="translate(-1066.45 -431.308)"
                            fill="#fff"
                        />
                        <path
                            id="Path_6"
                            data-name="Path 6"
                            d="M1393.363,466.218l.726.524a2.937,2.937,0,0,0-.282,1.179,3.547,3.547,0,0,0,1.7,2.872,9.338,9.338,0,0,0,5.18,1.34,10.87,10.87,0,0,0,4.5-.847c1.179-.484,2.509-1.582,2.509-2.993,0-1.623-1.139-2.268-2.59-2.187l-4.294.2c-2.873.121-5.5-.04-7.861-1.9a9.151,9.151,0,0,1-3.316-7.448,10.867,10.867,0,0,1,4.858-9.192c2.913-1.865,7.327-1.865,10.693-1.865h9.635v1.008c-2.429.2-2.227,2.59-2.227,4.455v8.426c0,1.5-.242,2.63.887,3.769,1.3,1.26,2.066,2.066,2.066,4.092,0,3.155-3.034,5.14-5.634,6.158a26.542,26.542,0,0,1-8.869,1.421c-4.172,0-8.627-.645-11.943-3.366Zm11.671-17.819c-4.858-.363-7.286,3.689-7.286,8.1,0,3.407,1.34,6.924,5.261,6.924,2.227,0,2.026-1.5,2.026-3.155Z"
                            transform="translate(-1300.065 -436.164)"
                            fill="#fff"
                        />
                        <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M1671.51,459.417a9.319,9.319,0,0,1-3.81-7.942c0-7.126,5.876-10.976,12.437-10.976,6.48,0,11.782,3.528,11.782,10.573,0,6.964-5.835,10.451-12.145,10.451A15.579,15.579,0,0,1,1671.51,459.417Zm12.306-7.781c0-3.034-.766-8.063-4.132-8.063-3.275,0-3.89,4.173-3.89,6.642,0,2.671.806,8.224,4.415,8.184C1683.292,458.359,1683.815,453.461,1683.815,451.637Z"
                            transform="translate(-1550.587 -431.308)"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </LogoWrapper>
            <TitleWrapper>
                <Typography variant={'caption'}>Moda Ayak İzi Hesaplaması</Typography>
            </TitleWrapper>
        </NavbarWrapper>
    );
};

export default Navbar;
