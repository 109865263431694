import { Grid, styled, Typography } from '@mui/material';
import { FC, useState } from 'react';
import ShareDialog from './ShareDialog';
import { State } from '../../pages/Result';
import { CDN } from '../../constants/cdnUrls';

const ShareButton = styled('button')(({ theme }) => ({
    '&:hover': {
        border: `4px solid ${theme.palette.success.main}`,
        fontWeight: 'normal',
        boxShadow: 'none',
        transition: '.3s',
    },
    position: 'absolute',
    bottom: '20px',
    right: '10rem',
    cursor: 'pointer',
    color: theme.palette.success.main,
    backgroundColor: '#E3AE4B',
    height: '3.938em',
    width: '18em',
    minWidth: 'fit-content',
    border: '1px solid #707070',
    borderRadius: '13px',
    fontWeight: 'bold',
    transition: '0.3s',
    marginTop: '1em',
}));

const ResultShare: FC<{ result: any; state: any; matches: boolean }> = ({ result, state, matches }) => {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const Status = {
        ['LOW']: {
            id: 1,
            status: 'LOW',
            title: 'Kıdemli Döngüsel Kahraman',
            content:
                'Selam Kıdemli Döngüsel Kahraman, sen döngüsel moda hareketinin en önünde yürüyenlerdensin. Bu\n' +
                'yüzden, Moda ayak izini hesaplamak için hemen tıkladığını ve sonucunu çevrenle paylaşacağını\n' +
                'biliyorum. Çünkü sen lider olmak ve toplulukları harekete geçirmek için doğmuşsun.\n' +
                '<br/>' +
                '<br/>' +
                'Bilgeliğinden birçok insanın yararlanması için, öğrendiklerini paylaşmaktan çekinmiyorsun.\n' +
                'Etrafındakileri de bu amaçla harekete geçirmek isteyen, senin gibi bir Kıdemli Döngüsel Kahraman\n' +
                'bizimle olduğu için çok mutluyuz!',
            icon: CDN.result.sharedCards.hero1Title,
            image: CDN.result.share.web.low,
            height: '400px',
            backgroundColor: '#00675A',
            card: CDN.result.sharedCards.hero1,
            cardTitle: CDN.result.sharedCards.hero1Title,
        },
        ['LOW_MEDIUM']: {
            id: 2,
            status: 'LOW_MEDIUM',
            title: 'Döngüsel Kahraman',
            content:
                'Selam Döngüsel Kahraman, sende harekete geçmiş bir kahraman olmanın gururlu heyecanı var.\n' +
                'Çünkü sürdürülebilir iyi bir dünya için, moda alışkanlıklarını değiştirmişsin bile. Her bir ürünün,\n' +
                'üretilirken harcanan enerjinin ve kaynaklarımızın değerini biliyorsun. Kullanmadığın ürünleri, atmak\n' +
                'yerine hayata döndürüyorsun.\n' +
                '<br/>' +
                '<br/>' +
                'Şimdiye kadar seninle milyarlarca litre su israfının ve milyonlarca kg karbon emisyonunun önüne\n' +
                'geçtik. Fakat yapacaklarımız bitmedi. Pelerinini sıkıca bağla! Dünya için harika şeyler yapacağız.\n' +
                'Hazırsan <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’yla</a> keşfetmeye başla.',
            icon: CDN.result.sharedCards.hero2Title,
            image: CDN.result.share.web.lowMedium,
            height: '500px',
            backgroundColor: '#00675A',
            card: CDN.result.sharedCards.hero2,
            cardTitle: CDN.result.sharedCards.hero2Title,
        },
        ['MEDIUM']: {
            id: 3,
            status: 'MEDIUM',
            title: 'Döngüsel Kaşif',
            content:
                'Selam Döngüsel Kâşif, sen yeni rotaları keşfetmek için doğmuşsun! Her zaman yenilik ve maceraya\n' +
                'açıksın. Bildiklerin sana az geliyor ve sürekli farklı deneyimler keşfetmek istiyorsun. Dünyamızın\n' +
                'kaynaklarını ve yarınlarımızı korumak için yeni bir alışveriş deneyimi peşine düşmüşsün.\n' +
                '<br/>' +
                '<br/>' +
                'O zaman hadi kemerini bağla, çünkü seninle çok farklı bir yolculuğa çıkıyoruz. Bu sefer haritaya\n' +
                'ihtiyacın yok! Döngüsel Dönüşüm yolculuğuna çıkmaya hazırsın. Keşfetmeye <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’dan başla!</a>',
            icon: CDN.result.sharedCards.hero3Title,
            image: CDN.result.share.web.medium,
            height: '500px',
            backgroundColor: '#E3AE4B',
            card: CDN.result.sharedCards.hero3,
            cardTitle: CDN.result.sharedCards.hero3Title,
        },
        ['MEDIUM_HIGH']: {
            id: 4,
            status: 'MEDIUM_HIGH',
            title: 'Anti Kahraman',
            content:
                'Selam Anti Kahraman, “hiçbir yere ait hissedemiyorum” dediğini duyar gibiyim. Bu testi de biraz derin\n' +
                'düşüncelerden uzaklaşmak, eğlenceli bir şeyler yapmak için seçmişsin. Dolabını açtığında gördüğün o\n' +
                'karmaşadan ruhun sıkılmış. Modaya ilgilisin, ama var olan düzeni de sorgulamadan edemiyorsun.\n' +
                'Gücünü dünyayı değiştirmek için nasıl kullanacağını henüz keşfetmemişsin ama endişelenme!\n' +
                '<br/>' +
                '<br/>' +
                'Çünkü sende Kahraman Ruhu var. Bu yüzden seni, herkesi kucaklayan, cesaretini ortaya çıkaracak\n' +
                'döngüsel dönüşüm hareketi <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’ya</a> davet ediyorum. Hadi dünyayı birlikte kurtaralım!',
            icon: CDN.result.sharedCards.hero4Title,
            image: CDN.result.share.web.mediumHigh,
            height: '400px',
            backgroundColor: '#CD3232',
            card: CDN.result.sharedCards.hero4,
            cardTitle: CDN.result.sharedCards.hero4Title,
        },
        ['HIGH']: {
            id: 5,
            status: 'HIGH',
            title: 'Hızlı Moda Canavarı',
            content:
                'Selam Hızlı Moda Canavarı, cevaplarına göre sen tam bir moda tutkunusun. Beğendiğin her ürünü\n' +
                'almak istiyorsun. Görüyorum ki dolabında aynı modelden bir sürü farklı renkte, çok da kullanmadığın\n' +
                'kıyafetlerin var. İhtiyaçtan fazla alınan ürünlerin dünyamızda oluşturduğu yükü henüz\n' +
                'keşfetmemişsin, ama endişelenme. Çünkü sana henüz denemediğin ama modası asla geçmeyecek bir\n' +
                'öneriyle geldim.\n' +
                '<br/>' +
                '<br/>' +
                'Unutma, sadece bu yılın değil geleceğin trendi Döngüsel Moda! Hadi sana en yakın döngüsel\n' +
                'mağazalarımızla <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’yu</a> keşfetmeye hemen başla!',
            icon: CDN.result.sharedCards.hero5Title,
            image: CDN.result.share.web.high,
            height: '500px',
            backgroundColor: '#CD3232',
            card: CDN.result.sharedCards.hero5,
            cardTitle: CDN.result.sharedCards.hero5Title,
        },
    };

    const widthCalculator = (): number => {
        switch (state) {
            case State.LOW:
                return 6;
            case State.LOW_MEDIUM:
                return 4;
            case State.MEDIUM:
                return 3.5;
            case State.MEDIUM_HIGH:
                return 5;
            case State.HIGH:
                return 3.5;
            default:
                return 6;
        }
    };

    return (
        <>
            <Grid
                container
                item
                lg={9}
                md={9}
                xs={12}
                pl={4}
                style={{
                    height: '500px',
                    backgroundImage: `url(${Status[state as keyof typeof Status]?.image})`,
                    backgroundSize: `auto ${Status[state as keyof typeof Status]?.height}`,
                    backgroundRepeat: 'no-repeat',
                    justifyContent: 'end',
                    backgroundPosition: 'top right',
                }}
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
            >
                <Grid container item xl={5} lg={widthCalculator()} md={widthCalculator()} xs={12}>
                    <Grid container item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <img
                            src={Status[state as keyof typeof Status]?.icon}
                            alt={Status[state as keyof typeof Status]?.title}
                            style={{
                                width: '15vw',
                                maxWidth: '320px',
                                height: 'auto',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <Typography variant={'subtitle2'}>
                            <div dangerouslySetInnerHTML={{ __html: Status[state as keyof typeof Status]?.content }} />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xl={7}
                    lg={12 - widthCalculator()}
                    md={12 - widthCalculator()}
                    xs={12}
                    style={{
                        height: 'inherit',
                        position: 'relative',
                        bottom: '0',
                    }}
                >
                    <ShareButton onClick={() => setShareDialogOpen(true)}>
                        <Typography
                            variant={'body2'}
                            sx={{
                                fontWeight: 'bold',
                                '&:hover': {
                                    fontWeight: 'normal',
                                },
                            }}
                        >
                            Sonucunu Paylaş!
                        </Typography>
                    </ShareButton>
                </Grid>
            </Grid>
            <ShareDialog
                shareDialogOpen={shareDialogOpen}
                setShareDialogOpen={setShareDialogOpen}
                hero={Status[state as keyof typeof Status]}
            />
        </>
    );
};
export default ResultShare;
