export const calculate = (global: any) => {
    const W =
        global?.question3State?.tops * 0.15 +
        global?.question3State?.bottom * 0.35 +
        global?.question3State?.outwear * 0.85 +
        global?.question3State?.shoes * 0.6 +
        global?.question3State?.bags * 1.2;

    /* SORU 1 - Sence dolabının yüzde kaçını kullanıyorsun? 0-100  */
    const C1 = 0;

    /* SORU 2 - Ne kadar sıklıkla alışveriş yapıyorsun? 0-50  */
    const C2 = ((250 * 250) / 1000000) * 0.48674 * global?.question2State?.parameter * global?.question2State?.time;

    /* SORU 3 - Aşağıdaki kategorilerden yılda kaç ürün alıyorsun?  */
    const C3 =
        global?.question3State?.tops * 7.5 +
        global?.question3State?.bottom * 19 +
        global?.question3State?.outwear * 10.31 +
        global?.question3State?.shoes * 9.6 +
        global?.question3State?.bags * 15;

    /* SORU 4 - Yaptığın alışverişlerin yüzde kaçını iade ediyorsun? */
    const C4 = (global?.question4State?.shopping / 100) * W * 0.25 * 0.445;

    /* SORU 5 - Satın aldığın ürünlerin yüzde kaçında: */
    const C5 = (-(global?.question5State?.secondHand + global?.question5State?.nivogo) / 100) * C3 * 0.5;

    /**/
    const C6 = 0;

    /* SORU 6 - Ayda kaç kez çamaşır makinesi çalıştırıyorsun? */
    const C7 = 0.579 * global?.question6State?.washingMachine * 12;

    /* SORU 7.1 - Kıyafetlerini hangi sıcaklıkta yıkarsın? */
    const C8 = -((global?.question6State?.washingMachine * 0.75 * global?.question7State?.washTemp) / 100);

    /* SORU 7.2 - Kıyafetlerini nasıl kurutursun? */
    const C9 = (0.771 * global?.question6State?.washingMachine * 12 * global?.question7State?.howDry) / 100;

    /* SORU 8 - Kıyafetlerini ne kadar süre kullanırsın? */
    const C10 = 0;

    /* SORU 9 - Kullanmadığın kıyafetleri ne yaparsınn? ? */
    const C11 =
        W * (global?.question9State?.whatDo / 100) * 0.467 + W * (1 - global?.question9State?.whatDo / 100) * 0.021;

    /* RESULT */
    const footPrint = C1 + C2 + C3 + C4 + C5 + C6 + C7 + C8 + C9 + C10 + C11;

    return {
        W,
        C1,
        C2,
        C3,
        C4,
        C5,
        C6,
        C7,
        C8,
        C9,
        C10,
        C11,
        footPrint,
    };
};
