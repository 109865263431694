import { Grid, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import Information from './Information';
import { InformationData } from '../constants/InformationData';
import MobileFooter from './MobileFooter';

interface IProps {
    question: string;
}

const MobileBottomWrapper: FC<IProps> = ({ question }) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    return (
        <Grid item sm={12} md={12} lg={12}>
            <Grid item xs={12}>
                {!matches ? <Information content={InformationData[question as keyof typeof InformationData]} /> : null}
            </Grid>
            <MobileFooter />
        </Grid>
    );
};

export default MobileBottomWrapper;
