import { Link, Typography } from '@mui/material';
import { FC } from 'react';

const PoweredBy: FC<{ dark?: boolean }> = ({ dark = false }) => {
    return (
        <Typography variant={'subtitle2'} color={dark ? '#00675A' : '#ffffff'}>
            © Powered by{' '}
            <Link
                href="https://www.ecofoni.com/"
                rel="noreferrer"
                target="_blank"
                color={'white'}
                sx={{
                    color: dark ? '#00675A' : '#ffffff',
                    '&:hover': {
                        color: dark ? '#002e28' : '#b8b8b8',
                        textDecoration: 'none',
                    },
                }}
            >
                Ecofoni
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
};

export default PoweredBy;
