import { styled, Typography } from '@mui/material';
import { FC } from 'react';

interface IStepButtonProps {
    increase: () => void;
    decrease?: () => void;
}

const StepWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    maxWidth: '100vw',
}));
const StepButtonWrapper = styled('button')(({ theme }) => ({
    '&:hover': {
        border: `0.25em solid ${theme.palette.success.main}`,
        fontWeight: 'normal',
        boxShadow: 'none',
        transition: '.3s',
    },
    cursor: 'pointer',
    margin: '1.875em',
    color: theme.palette.success.main,
    backgroundColor: '#E3AE4B',
    height: '3.938em',
    width: '9em',
    border: '1px solid #707070',
    borderRadius: '0.813em',
    fontWeight: 'medium',
    transition: '0.3s',
}));

const StepButton: FC<IStepButtonProps> = ({ increase, decrease }) => {
    return (
        <StepWrapper>
            <StepButtonWrapper onClick={decrease}>
                <Typography
                    variant={'button'}
                    fontWeight={'normal'}
                    sx={{
                        '&:hover': {
                            fontWeight: 'bold',
                        },
                    }}
                >
                    Geri
                </Typography>
            </StepButtonWrapper>
            <StepButtonWrapper onClick={increase}>
                <Typography
                    variant={'button'}
                    fontWeight={'normal'}
                    sx={{
                        '&:hover': {
                            fontWeight: 'bold',
                        },
                    }}
                >
                    İleri
                </Typography>
            </StepButtonWrapper>
        </StepWrapper>
    );
};

export default StepButton;
