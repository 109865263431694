import { IPutSurvey } from './types';

export const BASE_URL = 'https://api.modaayakizim.com/api/emissionSurvey';
//export const BASE_URL = 'http://52.73.3.179:8080/api/emissionSurvey';

export const getEmissionSurvey = async () => {};
export const updateEmissionSurvey = async (data: IPutSurvey) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'access-control-allow-origin': '*',
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    };

    return await fetch(BASE_URL, requestOptions);
};
