import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

interface IQuestionProps {
    text: string;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

const QuestionWrapper = styled('div')(({ theme }) => ({
    marginBottom: '20px',
}));

const Question: FC<IQuestionProps> = ({ text, variant = 'body1' }) => {
    return (
        <QuestionWrapper>
            <Typography variant={variant}>{text}</Typography>
        </QuestionWrapper>
    );
};

export default Question;
