import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

const Avarage: FC<{ result: any; state: any; matches: boolean; avarage: number }> = ({
    result,
    state,
    matches,
    avarage,
}) => {
    const Status = {
        ['LOW']: {
            backgroundColor: '#00675A',
            circleColor: '#B1D184',
            value: 100,
        },
        ['LOW_MEDIUM']: {
            backgroundColor: '#00675A',
            circleColor: '#B1D184',
            value: 120,
        },
        ['MEDIUM']: {
            backgroundColor: '#E3AE4B',
            circleColor: '#FAE378',
            value: 140,
        },
        ['MEDIUM_HIGH']: {
            backgroundColor: '#CD3232',
            circleColor: '#FF927E',
            value: 160,
        },
        ['HIGH']: {
            backgroundColor: '#CD3232',
            circleColor: '#FF927E',
            value: 200,
        },
    };

    const checkStatus = () => {
        if (state === 'LOW') return true;
        if (state === 'LOW_MEDIUM') return true;
        return false;
    };

    const Bar: FC<{ value: number; state: any }> = ({ value, state }) => {
        return (
            <Grid
                item
                xs={12}
                sx={{ alignItems: 'end', display: 'flex', justifyContent: 'center', marginBottom: '80px' }}
            >
                <div style={{ textAlign: 'center', marginRight: '20px' }}>
                    <div
                        style={{
                            borderRadius: '10px',
                            maxHeight: '200px',
                            minHeight: '100px',
                            height: `${Status[state as keyof typeof Status].value}px`,
                            width: '80px',
                            backgroundColor: '#FFFFFF',
                            textAlign: 'center',
                        }}
                    ></div>
                    <Typography variant={'inherit'}>Sen:</Typography>
                    <Typography variant={'inherit'}>
                        <b>{isNaN(value) ? 0 : Math.ceil(value)} kg</b>
                    </Typography>
                </div>
                <div>
                    <div
                        style={{
                            borderRadius: '10px',
                            height: '140px',
                            width: '80px',
                            backgroundColor: Status[state as keyof typeof Status]?.circleColor,
                        }}
                    ></div>
                    <Typography variant={'inherit'}>Ortalama:</Typography>
                    <Typography variant={'inherit'}>
                        <b>{avarage} kg</b>
                    </Typography>
                </div>
            </Grid>
        );
    };

    const calculate = () => {
        const inc = result?.footPrint - avarage;
        const temp = (inc / avarage) * 100;
        const res = Math.ceil(Math.abs(temp));
        return isNaN(res) ? 0 : res;
    };

    return (
        <Grid
            item
            container
            lg={3}
            md={3}
            xs={12}
            style={{
                height: '500px',
                backgroundColor: Status[state as keyof typeof Status]?.backgroundColor,
                color: '#FFF',
            }}
            alignItems={'space-between'}
            justifyContent={'center'}
            display={'flex'}
        >
            <Grid item xs={12} mt={4}>
                {checkStatus() && (
                    <Typography variant={'body1'} color={'#FFF'}>
                        Tebrikler!
                    </Typography>
                )}

                <Typography variant={'body2'} color={'#FFF'}>
                    Ayak izin, ortalamanın
                </Typography>
                <Typography
                    variant={'body2'}
                    sx={{
                        margin: 'auto',
                        width: '170px',
                        backgroundColor: '#fff',
                        color: Status[state as keyof typeof Status]?.backgroundColor,
                    }}
                >
                    %{calculate()} {result?.footPrint >= avarage ? 'üstünde!' : 'altında!'}
                </Typography>
                <Typography variant={'subtitle2'} color={'#FFF'}>
                    Yıllık moda ayak izin
                </Typography>
                <Typography variant={'subtitle2'} color={'#FFF'}>
                    {Math.ceil(result?.footPrint) || 0} Kg. Karbon emisyonuna denk geliyor.
                </Typography>
            </Grid>

            <Bar value={result.footPrint} state={state} />
        </Grid>
    );
};
export default Avarage;
