import { FC, useEffect } from 'react';
import LandingButton from '../../components/LandingButton';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import Footer from '../../components/Footer';
import { CDN } from '../../constants/cdnUrls';

interface IPageProps {
    setActiveStep(i: number): void;
}

const WebLanding: FC<IPageProps> = ({ setActiveStep }) => {
    const matches = useMediaQuery('(min-height: 750px)');
    console.log(CDN.landing.web);
    useEffect(() => {
        document.title = 'Nivogo - Moda Ayak İzi Testi';
      }, []);
    return (
        <>
            <Grid
                container
                sx={{
                    height: 'calc(100vh - 135px)',
                    boxSizing: 'border-box',
                    backgroundImage: `url(${CDN.landing.web})`,
                    backgroundSize: 'cover',
                }}
            >
                <Grid item sm={12} xs={12} md={12} lg={5} xl={5}></Grid>

                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={7}
                    xl={7}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Grid pl={2} pr={2}>
                        <img
                            src={CDN.landing.text}
                            alt={'Landing'}
                            style={
                                matches
                                    ? {
                                          width: '60%',
                                          height: 'auto',
                                      }
                                    : { width: '50vh', height: 'auto' }
                            }
                        />
                        <Typography variant={'body2'} sx={{ color: '#00675A', textAlign: 'center', marginTop: '20px' }}>
                            Moda endüstrisinin yeryüzündeki sera gazı salımının %10’undan sorumlu olduğunu eğer hiçbir
                            değişiklik yapılmazsa bu oranın 2050 yılında %26’ya yükseleceğini biliyor muydun? Bu yaşamak
                            için 2,8 adet dünyaya ihtiyacımız olacak anlamına geliyor. Dolabındaki ürünlerin ve moda
                            alışkanlıklarının dünyamıza etkisini Nivogo’yla keşfetmeye şimdi başla, dolabın ve dünyamız
                            için gerçek fırsatları kaçırma!
                        </Typography>
                        <LandingButton title={'Moda Ayak İzini Hesapla'} onClick={() => setActiveStep(1)} />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default WebLanding;
