import React, { FC } from 'react';
import StepButton from '../components/StepButton';
import Question from '../components/Question';
import Steps from '../components/Steps';
import { Grid, useMediaQuery } from '@mui/material';
import QuestionWrapper from '../components/QuestionWrapper';
import Range from '../components/Range';
import Information from '../components/Information';
import { InformationData } from '../constants/InformationData';
import MobileBottomWrapper from '../components/MobileBottomWrapper';
import { CDN } from '../constants/cdnUrls';

interface IPageProps {
    activeStep: number;

    setActiveStep(i: number): void;

    state: {
        use: number;
    };
    setState: React.Dispatch<React.SetStateAction<{ use: number }>>;

    calculationEvent: {
        event: any;
        step: any;
        yanit: any;
        yuzde_dolap_kullanimi: any;
        alisveris_sikligi: any;
        kategori: any;
        yuzde_iade: any;
        yuzde_ikinci_el_tercih: any;
        yuzde_nivogo_tercih: any;
        camasir_makinesi_kullanim: any;
        yikama_sicaklik: any;
        kurutma_tipi: any;
        kiyafet_kullanim_suresi: any;
        kullanilmayan_giysi_degerlendirme: any;
    };

    setCalculationEvent: React.Dispatch<
        React.SetStateAction<{
            event: any;
            step: any;
            yanit: any;
            yuzde_dolap_kullanimi: string;
            alisveris_sikligi: any;
            kategori: any;
            yuzde_iade: any;
            yuzde_ikinci_el_tercih: any;
            yuzde_nivogo_tercih: any;
            camasir_makinesi_kullanim: any;
            yikama_sicaklik: any;
            kurutma_tipi: any;
            kiyafet_kullanim_suresi: any;
            kullanilmayan_giysi_degerlendirme: any;
        }>
    >;
}

const question = 'Sence dolabının yüzde kaçını kullanıyorsun?';

const Question1: FC<IPageProps> = ({
    activeStep,
    setActiveStep,
    state,
    setState,
    calculationEvent,
    setCalculationEvent,
}) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    // @ts-ignore
    return (
        <Grid container sx={{ height: 'calc(100vh - 65px)' }}>
            <Grid item sm={12} md={6} lg={6}>
                <img
                    alt={'white_1'}
                    src={matches ? CDN.question1.web.white : CDN.question1.mobile.white}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                    }
                />
                <img
                    alt={'dark_1'}
                    src={matches ? CDN.question1.web.dark : CDN.question1.mobile.dark}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: 10 / state.use,
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: 10 / state.use,
                              }
                    }
                />
                {matches ? <Information content={InformationData['Question1']} /> : null}
            </Grid>
            <Grid
                item
                sm={12}
                md={12}
                lg={6}
                sx={
                    matches
                        ? null
                        : {
                              marginTop: '70%',
                              width: 'calc(100% - 60px)',
                              marginRight: '30px',
                              marginLeft: '30px',
                          }
                }
            >
                <Steps activeStep={activeStep} />
                <QuestionWrapper>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Question text={question} />
                        <Range
                            rightTitle={`%${state.use}`}
                            min={0}
                            max={100}
                            value={state.use}
                            onChange={(e) => {
                                setState({ use: +e.target.value });
                            }}
                        />
                    </Grid>
                    <StepButton
                        increase={() => {
                            setActiveStep(2);
                            setCalculationEvent({
                                ...calculationEvent,
                                step: 'soru1',
                                yuzde_dolap_kullanimi: `%${state.use}`,
                                yanit: `%${state.use}`,
                            });
                        }}
                        decrease={() => setActiveStep(0)}
                    />
                </QuestionWrapper>
            </Grid>
            {!matches && <MobileBottomWrapper question={'Question1'} />}
        </Grid>
    );
};

export default Question1;

