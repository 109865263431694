import { Dialog, DialogContent, Grid } from '@mui/material';
import React, { FC } from 'react';
import ShareSocialMedia from './ShareSocialMedia';

interface IDialogProps {
    shareDialogOpen: boolean;
    setShareDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    hero: {
        id: number;
        status: string;
        title: string;
        content: string;
        icon: any;
        image: any;
        height: string;
        backgroundColor: string;
        card: any;
        cardTitle: any;
    };
}

const ShareDialog: FC<IDialogProps> = ({ shareDialogOpen, setShareDialogOpen, hero }) => {
    const shareText = `’nun moda ayak izi testini çözdüm, benim döngüsel kimliğim ${hero?.title}. Moda alışkanlıklarına göre sen hangi kahramansın? Moda ayak izini şimdi keşfet: `;
    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason == 'backdropClick') {
            setShareDialogOpen(false);
        }
    };

    return (
        <Dialog
            open={shareDialogOpen}
            onClose={handleClose}
            scroll={'body'}
            sx={{
                backdropFilter: 'blur(10px)',
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        minWidth: '200px',
                        maxWidth: '500px',
                        borderRadius: '28px',
                    },
                },
            }}
        >
            <DialogContent>
                <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} rowSpacing={1}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img src={hero.cardTitle} alt={'title'} style={{ width: '40%', height: 'auto' }} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img
                            src={hero.card}
                            alt={'hero'}
                            style={{
                                width: '70%',
                                height: 'auto',
                                padding: 0,
                                border: `5px solid ${hero.backgroundColor}`,
                                borderRadius: '10px',
                                boxSizing: 'border-box',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <ShareSocialMedia
                            hero={hero?.card}
                            setCloseDialog={setShareDialogOpen}
                            downloadTitle={hero?.title}
                            shareText={shareText}
                            shareResultDialog={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ShareDialog;
