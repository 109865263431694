import * as React from 'react';
import { Grid } from '@mui/material';

interface BoxProps {
    children: React.ReactNode;
}

const QuestionWrapper = (props: BoxProps) => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div>{props.children}</div>
        </Grid>
    );
};

export default QuestionWrapper;
