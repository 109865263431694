import { Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

interface IInformationProps {
    content: string;
}

const Information: FC<IInformationProps> = ({ content }) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    const InformationHeaderText = styled('p')(({ theme }) => ({
        fontWeight: 500,
        [theme.breakpoints.up('xs')]: {
            fontSize: matches ? '0.6rem' : '1.2rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: matches ? '0.7rem' : '1.3rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: matches ? '0.8rem' : '1.4rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: matches ? '0.9rem' : '1.5rem',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: matches ? '1rem' : '1.5rem',
        },
    }));

    const InformationText = styled('p')(({ theme }) => ({
        fontWeight: 400,
        [theme.breakpoints.up('xs')]: {
            fontSize: matches ? '0.5rem' : '1rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: matches ? '0.6rem' : '1.1rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: matches ? '0.7rem' : '1.2rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: matches ? '0.8rem' : '1.3rem',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: matches ? '0.9rem' : '1.4rem',
        },
    }));

    const InformationWrapper = styled('div')(({ theme }) => ({
        color: '#2B655A',
        position: matches ? 'absolute' : 'static',
        bottom: '0',
        left: '0',
        textAlign: 'start',
        padding: '10px',
        maxWidth: matches ? '35%' : '100%',
        marginTop: matches ? 0 : '20px',
    }));

    return (
        <InformationWrapper>
            <InformationHeaderText>Biliyor Muydun ?</InformationHeaderText>
            <InformationText>{content}</InformationText>
        </InformationWrapper>
    );
};

export default Information;
