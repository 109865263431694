import { Grid, Slider, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { CDN } from '../../constants/cdnUrls';

const FootPrintSlider: FC<{ result: any; state: any; matches: boolean }> = ({ result, state, matches }) => {
    const FootText = styled('p')(({ theme }) => ({
        marginTop: '0',
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.7rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.2rem',
        },
    }));

    const Status = {
        ['LOW']: {
            value: 0,
            railBorderColor: '#00453C',
            trackBackgroundColor: '#00675A',
        },
        ['LOW_MEDIUM']: {
            value: 25,
            railBorderColor: '#00453C',
            trackBackgroundColor: '#00675A',
        },
        ['MEDIUM']: {
            value: 50,
            railBorderColor: '#9A7023',
            trackBackgroundColor: '#E3AE4B',
        },
        ['MEDIUM_HIGH']: {
            value: 75,
            railBorderColor: '#801919',
            trackBackgroundColor: '#CD3232',
        },
        ['HIGH']: {
            value: 100,
            railBorderColor: '#801919',
            trackBackgroundColor: '#CD3232',
        },
    };

    const marks = [
        {
            value: 0,
            label: <FootText>Düşük</FootText>,
        },
        {
            value: 25,
            label: <FootText>Düşük Orta</FootText>,
        },
        {
            value: 50,
            label: <FootText>Orta</FootText>,
        },
        {
            value: 75,
            label: <FootText>Orta Yüksek</FootText>,
        },
        {
            value: 100,
            label: <FootText>Yüksek</FootText>,
        },
    ];

    return (
        <Grid item lg={5} md={8} xs={10} p={3} pt={2} pb={0}>
            <Slider
                marks={marks}
                sx={{
                    height: '65px',
                    marginTop: '20px',
                    '& .MuiSlider-markLabel': {
                        position: 'absolute',
                        top: 0,
                        color: '#2B655A',
                        fontSize: '16px',
                    },
                    '& .MuiSlider-thumb': {
                        appearance: 'none',
                        position: 'absolute',
                        top: '2.7rem',
                        cursor: 'ew-resize',
                        height: '37px',
                        width: '90px',
                        marginLeft: '6px',
                        marginRight: '6px',
                        borderRadius: 0,
                        backgroundImage: `url(${CDN.result.sliderFoot})`,
                        backgroundColor: 'transparent',
                    },
                    '& .MuiSlider-track': {
                        width: '1em',
                        height: '1.15em',
                        borderRadius: '19px',
                        backgroundColor: Status[state as keyof typeof Status]?.trackBackgroundColor,
                        border: 'none',
                    },
                    '& .MuiSlider-rail': {
                        height: '1em',
                        borderRadius: '19px',
                        border: `3px solid ${Status[state as keyof typeof Status]?.railBorderColor}`,
                        backgroundColor: '#BFEDD7',
                        opacity: 1,
                    },
                }}
                min={0}
                max={100}
                value={Status[state as keyof typeof Status]?.value}
                disabled
            />
        </Grid>
    );
};

const Foot: FC<{ result: any; state: any; matches: boolean }> = ({ result, state, matches }) => {
    const Status = {
        ['LOW']: {
            id: 1,
            title: 'Sen, Kıdemli Döngüsel Kahramansın!',
        },
        ['LOW_MEDIUM']: {
            id: 2,
            title: 'Sen, Döngüsel Kahramansın!',
        },
        ['MEDIUM']: {
            id: 3,
            title: 'Sen, Döngüsel Kaşifsin!',
        },
        ['MEDIUM_HIGH']: {
            id: 4,
            title: 'Sen, Anti Kahramansın!',
        },
        ['HIGH']: {
            id: 5,
            title: 'Sen, Hızlı Moda Canavarısın!',
        },
    };

    return (
        <Grid
            item
            container
            xs={12}
            pt={2}
            pb={3}
            style={{ height: matches ? '200px' : '160px', backgroundColor: '#C8ECD8' }}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
        >
            <Grid item xs={12}>
                <Typography variant={'body2'}>İşte, Moda Ayak İzin:</Typography>
                {matches && <Typography variant={'body1'}>{Status[state as keyof typeof Status]?.title}</Typography>}
            </Grid>
            <FootPrintSlider result={result} state={state} matches={matches} />
        </Grid>
    );
};

export default Foot;
