import { styled, Typography } from '@mui/material';

const FooterWrapper = styled('footer')(({ theme }) => ({
    width: '100%',
    height: '50px',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    marginTop: '20px',
}));

const MobileFooter = () => {
    return (
        <FooterWrapper>
            <Typography variant={'subtitle1'} sx={{ color: '#fff' }}>
                Moda Ayak İzi Hesaplaması
            </Typography>
        </FooterWrapper>
    );
};

export default MobileFooter;
