import { styled } from '@mui/material';
import { FC } from 'react';

interface IStepsProps {
    activeStep: number;
}

interface ICircleProps {
    isSelected?: boolean;
}

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '30px',
}));

const Scope = styled('div')(({ theme }) => ({
    height: '4.75em',
}));

const NumberWrapper = styled('div')(({ theme }) => ({
    fontSize: '1.563em',
    fontWeight: 400,
    marginLeft: '10px',
    color: '#2B655A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
}));

const StepsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StepWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StepSmallWrapper = styled('div')(({ theme }) => ({
    width: '1.125em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StepCircle = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'disabled',
})<ICircleProps>(({ theme, isSelected }) => ({
    height: '6vw',
    width: '6vw',
    maxHeight: '2em',
    maxWidth: '2em',
    marginLeft: '1px',
    borderRadius: '50%',
    background: isSelected ? '#00675A' : '#E3AE4B',
    transition: '.5s',
}));
const StepSmallCircle = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'disabled',
})<ICircleProps>(({ theme, isSelected }) => ({
    height: '.5vh',
    width: '1vw',
    marginLeft: '1px',
    borderRadius: '50%',
    background: isSelected ? '#00675A' : '#E3AE4B',
    transition: '.5s',
}));

const Step = (isSelected: boolean = false) => {
    return (
        <StepWrapper>
            <StepCircle isSelected={isSelected} />
            <StepSmallWrapper>
                <StepSmallCircle isSelected={isSelected} />
                <StepSmallCircle isSelected={isSelected} />
                <StepSmallCircle isSelected={isSelected} />
            </StepSmallWrapper>
        </StepWrapper>
    );
};

const Steps: FC<IStepsProps> = ({ activeStep }) => {
    return (
        <Wrapper>
            <Scope>
                <NumberWrapper>
                    <b>{activeStep}</b> /9 Soru
                </NumberWrapper>
                <StepsWrapper>
                    {Step(activeStep >= 1)}
                    {Step(activeStep >= 2)}
                    {Step(activeStep >= 3)}
                    {Step(activeStep >= 4)}
                    {Step(activeStep >= 5)}
                    {Step(activeStep >= 6)}
                    {Step(activeStep >= 7)}
                    {Step(activeStep >= 8)}
                    <StepCircle isSelected={activeStep >= 9} />
                </StepsWrapper>
            </Scope>
        </Wrapper>
    );
};

export default Steps;
