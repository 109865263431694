export const InformationData = {
    ['Question1']:
        'Dolabımızdaki giysilerin sadece %20’sini kullanıyoruz. Kullanmadığın ürünleri Nivogo’ya getirerek\n' +
        'hayata döndürebilir, yalnızca bir tişörtü döngüye katarak yaklaşık 7 kg karbon emisyonunun\n' +
        'oluşmasının önüne geçebilirsin.',
    ['Question2']:
        'Bir kişi yılda ortalama 301 kez alışveriş merkezine gidiyor ve 400 saatini alışverişte geçiriyor. Bu\n' +
        'hayatının ortalama 8.5 yılını alışveriş yaparken geçiriyor demek.',
    ['Question3']:
        'Bir kişi yılda ortalama 68 yeni kıyafet satın alıyor. Bu, 40 sene önceye kıyasla yaklaşık 2 kat daha sık\n' +
        'alışveriş yapıldığı anlamına geliyor.',
    ['Question4']:
        'Dünya genelinde her yıl yaklaşık 30 milyon ürün satıcıya iade ediliyor ve bunların yalnızca bir kısmı\n' +
        'yeniden satılabiliyor. İade sürecinde, sadece lojistiğinin yarattığı karbon emisyonu ise 27 milyon ton.\n' +
        'Bu, 5.9 milyon arabanın bir yıl boyunca sürekli yolda olup havayı kirletmesiyle eşdeğer.',
    ['Question5']:
        'Yeni ürün almak yerine Nivogo’nun yenilenmiş ürünlerinden tercih ettiğinde karbon emisyonunun\n' +
        'yaklaşık yarısının önüne geçebilirsin.',
    ['Question6']:
        'Bir aile, çamaşır makinesini ayda ortalama 4 kez çalıştırıyor. Bu, ayda 2.3 kg karbon salınımına denk.\n' +
        'Ufak lekelerde kıyafetlerini makinede yıkamak yerine yalnızca o bölgeyi temizleyerek karbon\n' +
        'salınımını azaltmak mümkün.',
    ['Question7']:
        'Makinelerin sıcak su programları ve kurutma makineleri yüksek ısıyla çalıştıkları için yoğun enerji\n' +
        'tüketirler. Çamaşırları soğuk suyla yıkamak ve asarak kurutmak, karbon emisyonundan %80 tasarruf\n' +
        'sağlar.',
    ['Question8']:
        'Bir kıyafet ortalama 7 kez giyiliyor. Oysaki yalnızca bir ürünün 9 ay daha kullanılmasını sağlayarak\n' +
        'karbon ve su ayak izlerinin her birini yaklaşık %20-30 oranında azaltabilirsin.',
    ['Question9']:
        'Bir kişi yılda ortalama 37 kg tekstil ürününü çöpe atıyor. Bu, yaklaşık 17 kg karbon salımına sebep olur\n' +
        've bir ağacın 9 aylık karbondioksit absorbe etmesine denk gelir. Kullanmadığın giysileri Nivogo’ya\n' +
        'getirerek hayata döndürebilir, döngüsel modaya dahil ederek kullanım ömürlerini uzatabilirsin.',
};
