import React, { FC } from 'react';
import StepButton from '../components/StepButton';
import Steps from '../components/Steps';
import Question from '../components/Question';
import { Grid, useMediaQuery } from '@mui/material';
import RadioInput from '../components/RadioInput';
import QuestionWrapper from '../components/QuestionWrapper';
import Information from '../components/Information';
import { calculate } from '../services/calculate';
import { ICreateSurvey, ICreateSurveyResponse } from '../api/types';
import { IResult } from '../App';
import { InformationData } from '../constants/InformationData';
import MobileBottomWrapper from '../components/MobileBottomWrapper';
import { CDN } from '../constants/cdnUrls';
import { BASE_URL } from '../api/result';

interface IPageProps {
    activeStep: number;

    setActiveStep(i: number): void;

    state: { whatDo: number };
    setState: React.Dispatch<React.SetStateAction<{ whatDo: number }>>;
    setSurveyId: React.Dispatch<React.SetStateAction<string>>;
    setResult: React.Dispatch<React.SetStateAction<IResult>>;
    global: {
        question1State: any;
        question2State: any;
        question3State: any;
        question4State: any;
        question5State: any;
        question6State: any;
        question7State: any;
        question8State: any;
        question9State: any;
    };
    calculationEvent: {
        event: any;
        step: any;
        yanit: any;
        yuzde_dolap_kullanimi: any;
        alisveris_sikligi: any;
        kategori: any;
        yuzde_iade: any;
        yuzde_ikinci_el_tercih: any;
        yuzde_nivogo_tercih: any;
        camasir_makinesi_kullanim: any;
        yikama_sicaklik: any;
        kurutma_tipi: any;
        kiyafet_kullanim_suresi: any;
        kullanilmayan_giysi_degerlendirme: any;
    };

    setCalculationEvent: React.Dispatch<
        React.SetStateAction<{
            event: any;
            step: any;
            yanit: any;
            yuzde_dolap_kullanimi: string;
            alisveris_sikligi: any;
            kategori: any;
            yuzde_iade: any;
            yuzde_ikinci_el_tercih: any;
            yuzde_nivogo_tercih: any;
            camasir_makinesi_kullanim: any;
            yikama_sicaklik: any;
            kurutma_tipi: any;
            kiyafet_kullanim_suresi: any;
            kullanilmayan_giysi_degerlendirme: any;
        }>
    >;
}

const question = 'Kullanmadığın giysileri nasıl değerlendirirsin?';

const Question9: FC<IPageProps> = ({
    global,
    setResult,
    setSurveyId,
    activeStep,
    setActiveStep,
    state,
    setState,
    calculationEvent,
    setCalculationEvent,
}) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    const completedHandler = () => {
        setActiveStep(10);
        setCalculationEvent({
            ...calculationEvent,
            step: 'soru9',
            kullanilmayan_giysi_degerlendirme:
                state.whatDo == 100
                    ? `Hepsini çöpe atarım.`
                    : state.whatDo == 75
                    ? `Çoğunu çöpe atarım.`
                    : state.whatDo == 50
                    ? `Bazılarını çöpe atar, bazılarını bağışlar ya da Nivogo’ya veya farklı bir platforma satarım.`
                    : state.whatDo == 25
                    ? `Çoğunlukla bağışlar ya da Nivogo’ya veya farklı bir platforma satarım.`
                    : `Hepsini Nivogo’ya veya farklı bir platforma satarım.`,
            yanit:
                state.whatDo == 100
                    ? `Hepsini çöpe atarım.`
                    : state.whatDo == 75
                    ? `Çoğunu çöpe atarım.`
                    : state.whatDo == 50
                    ? `Bazılarını çöpe atar, bazılarını bağışlar ya da Nivogo’ya veya farklı bir platforma satarım.`
                    : state.whatDo == 25
                    ? `Çoğunlukla bağışlar ya da Nivogo’ya veya farklı bir platforma satarım.`
                    : `Hepsini Nivogo’ya veya farklı bir platforma satarım.`,
        });
        setResult(calculate(global));
        const data = calculate(global);
        createSurvey({
            answer1: data.C1,
            answer2: data.C2,
            answer3: data.C3,
            answer4: data.C4,
            answer5: data.C5,
            answer6: data.C6,
            answer7: data.C7,
            answer8: data.C8,
            answer9: data.C9,
            answer10: data.C10,
            answer11: data.C11,
            totalEmission: data.footPrint,
        });
    };

    const createSurvey = async (survey: ICreateSurvey) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'access-control-allow-origin': '*',
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(survey),
        };

        return await fetch(BASE_URL, requestOptions)
            .then((res) => res.json())
            .then((data: ICreateSurveyResponse) => setSurveyId(data?.emissionSurveyDto?.surveyId))
            .catch((err) => console.log(err));
    };

    return (
        <Grid container sx={{ height: 'calc(100vh - 65px)' }}>
            <Grid item sm={12} md={6} lg={6}>
                <img
                    alt={'white_9'}
                    src={matches ? CDN.question9.web.white : CDN.question9.mobile.white}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                    }
                />
                <img
                    alt={'dark_9'}
                    src={matches ? CDN.question9.web.dark : CDN.question9.mobile.dark}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: state.whatDo / 100,
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: state.whatDo / 100,
                              }
                    }
                />
                {matches ? <Information content={InformationData['Question9']} /> : null}
            </Grid>
            <Grid
                item
                sm={12}
                md={12}
                lg={6}
                sx={
                    matches
                        ? null
                        : {
                              marginTop: '70%',
                              width: 'calc(100% - 60px)',
                              marginRight: '30px',
                              marginLeft: '30px',
                          }
                }
            >
                <Steps activeStep={activeStep} />
                <QuestionWrapper>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Question text={question} />
                        <div style={{ maxWidth: matches ? '550px' : 'fit-content' }}>
                            <RadioInput
                                id={'question9'}
                                name={'question9'}
                                text={'Hepsini çöpe atarım.'}
                                value={100}
                                onChange={(e) => setState({ whatDo: +e.target.value })}
                            />
                            <RadioInput
                                id={'question9'}
                                name={'question9'}
                                text={'Çoğunu çöpe atarım. '}
                                value={75}
                                onChange={(e) => setState({ whatDo: +e.target.value })}
                            />
                            <RadioInput
                                id={'question9'}
                                name={'question9'}
                                text={
                                    'Bazılarını çöpe atar, bazılarını bağışlar ya da Nivogo’ya veya farklı bir platforma satarım.'
                                }
                                value={50}
                                onChange={(e) => setState({ whatDo: +e.target.value })}
                            />
                            <RadioInput
                                id={'question9'}
                                name={'question9'}
                                text={'Çoğunlukla bağışlar ya da Nivogo’ya veya farklı bir platforma satarım.'}
                                value={25}
                                onChange={(e) => setState({ whatDo: +e.target.value })}
                            />
                            <RadioInput
                                id={'question9'}
                                name={'question9'}
                                text={'Hepsini Nivogo’ya veya farklı bir platforma satarım.'}
                                value={0}
                                onChange={(e) => setState({ whatDo: +e.target.value })}
                                defaultChecked
                            />
                        </div>
                    </Grid>
                    <StepButton increase={completedHandler} decrease={() => setActiveStep(8)} />
                </QuestionWrapper>
            </Grid>
            {!matches && <MobileBottomWrapper question={'Question9'} />}
        </Grid>
    );
};

export default Question9;

