import { Grid, Typography, useMediaQuery } from '@mui/material';
import PoweredBy from '../PoweredBy';
import { CDN } from '../../constants/cdnUrls';

const FooterResult = () => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    return (
        <Grid
            container
            item
            spacing={2}
            xs={12}
            mt={matches ? 2 : 0}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
        >
            <Typography variant={'subtitle2'} color={'#2B655A'} p={4}>
                Nivogo Moda Ayak İzi Hesaplayıcısı kişilerin kendi yarattığı çevresel etki ve moda ayak izini fark
                etmelerini, harekete geçmelerini teşvik etmeyi amaçlar. Sürdürülebilirlik danışmanı Metsims iş
                birliğiyle hazırlanan metodoloji kapsamında hayata geçirilmiştir. Ek kaynaklar için:{' '}
                <a
                    href={'https://nivogo.com/cevresel-etki-olcumu/'}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                >
                    Çevresel Etki Ölçümü – Nivogo
                </a>
                <br />
                Nivogo, Türkiye’nin öncü Döngüsel Dönüşüm Hareketidir. Döngüsel Moda ilkelerini benimseyerek hızlı
                modanın sebep olduğu moda atığı miktarını azaltmaya odaklanır. Sürdürülebilir iş modeliyle, doğal
                kaynakların etkin kullanımını hedefler. Giysilerin ömrünü uzatarak modayı sürdürülebilir hale getirmeyi
                teşvik eder.
            </Typography>
            <Grid item xs={12}>
                <img
                    src={CDN.result.footerLogo}
                    alt={'result_footer_logo'}
                    style={{
                        width: '12rem',
                        height: 'auto',
                        marginBottom: '10px',
                    }}
                />
                <PoweredBy dark />
            </Grid>
        </Grid>
    );
};
export default FooterResult;
