import { Grid, styled, Typography } from '@mui/material';
import { FC, useState } from 'react';
import ShareDialog from '../ShareDialog';
import { CDN } from '../../../constants/cdnUrls';

const MobileShare: FC<{ state: any; result: any; matches: boolean }> = ({ state, result, matches }) => {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const Status = {
        ['LOW']: {
            id: 1,
            status: 'LOW',
            title: 'Kıdemli Döngüsel Kahraman',
            content:
                'Selam Kıdemli Döngüsel Kahraman, sen döngüsel moda hareketinin en önünde yürüyenlerdensin. Bu\n' +
                'yüzden, Moda ayak izini hesaplamak için hemen tıkladığını ve sonucunu çevrenle paylaşacağını\n' +
                'biliyorum. Çünkü sen lider olmak ve toplulukları harekete geçirmek için doğmuşsun.\n' +
                '<br/>' +
                '<br/>' +
                'Bilgeliğinden birçok insanın yararlanması için, öğrendiklerini paylaşmaktan çekinmiyorsun.\n' +
                'Etrafındakileri de bu amaçla harekete geçirmek isteyen, senin gibi bir Kıdemli Döngüsel Kahraman\n' +
                'bizimle olduğu için çok mutluyuz!',
            icon: CDN.result.low,
            image: CDN.result.share.mobile.low,
            height: '400px',
            backgroundColor: '#00675A',
            card: CDN.result.sharedCards.hero1,
            cardTitle: CDN.result.sharedCards.hero1Title,
        },
        ['LOW_MEDIUM']: {
            id: 2,
            status: 'LOW_MEDIUM',
            title: 'Döngüsel Kahraman',
            content:
                'Selam Döngüsel Kahraman, sende harekete geçmiş bir kahraman olmanın gururlu heyecanı var.\n' +
                'Çünkü sürdürülebilir iyi bir dünya için, moda alışkanlıklarını değiştirmişsin bile. Her bir ürünün,\n' +
                'üretilirken harcanan enerjinin ve kaynaklarımızın değerini biliyorsun. Kullanmadığın ürünleri, atmak\n' +
                'yerine hayata döndürüyorsun.\n' +
                '<br/>' +
                '<br/>' +
                'Şimdiye kadar seninle milyarlarca litre su israfının ve milyonlarca kg karbon emisyonunun önüne\n' +
                'geçtik. Fakat yapacaklarımız bitmedi. Pelerinini sıkıca bağla! Dünya için harika şeyler yapacağız.\n' +
                'Hazırsan <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’yla</a> keşfetmeye başla.',
            icon: CDN.result.lowMedium,
            image: CDN.result.share.mobile.lowMedium,
            height: '500px',
            backgroundColor: '#00675A',
            card: CDN.result.sharedCards.hero2,
            cardTitle: CDN.result.sharedCards.hero2Title,
        },
        ['MEDIUM']: {
            id: 3,
            status: 'MEDIUM',
            title: 'Döngüsel Kaşif',
            content:
                'Selam Döngüsel Kâşif, sen yeni rotaları keşfetmek için doğmuşsun! Her zaman yenilik ve maceraya\n' +
                'açıksın. Bildiklerin sana az geliyor ve sürekli farklı deneyimler keşfetmek istiyorsun. Dünyamızın\n' +
                'kaynaklarını ve yarınlarımızı korumak için yeni bir alışveriş deneyimi peşine düşmüşsün.\n' +
                '<br/>' +
                '<br/>' +
                'O zaman hadi kemerini bağla, çünkü seninle çok farklı bir yolculuğa çıkıyoruz. Bu sefer haritaya\n' +
                'ihtiyacın yok! Döngüsel Dönüşüm yolculuğuna çıkmaya hazırsın. Keşfetmeye <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’dan başla!</a>',
            icon: CDN.result.medium,
            image: CDN.result.share.mobile.medium,
            height: '500px',
            backgroundColor: '#E3AE4B',
            card: CDN.result.sharedCards.hero3,
            cardTitle: CDN.result.sharedCards.hero3Title,
        },
        ['MEDIUM_HIGH']: {
            id: 4,
            status: 'MEDIUM_HIGH',
            title: 'Anti Kahraman',
            content:
                'Selam Anti Kahraman, “hiçbir yere ait hissedemiyorum” dediğini duyar gibiyim. Bu testi de biraz derin\n' +
                'düşüncelerden uzaklaşmak, eğlenceli bir şeyler yapmak için seçmişsin. Dolabını açtığında gördüğün o\n' +
                'karmaşadan ruhun sıkılmış. Modaya ilgilisin, ama var olan düzeni de sorgulamadan edemiyorsun.\n' +
                'Gücünü dünyayı değiştirmek için nasıl kullanacağını henüz keşfetmemişsin ama endişelenme!\n' +
                '<br/>' +
                '<br/>' +
                'Çünkü sende Kahraman Ruhu var. Bu yüzden seni, herkesi kucaklayan, cesaretini ortaya çıkaracak\n' +
                'döngüsel dönüşüm hareketi <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’ya</a> davet ediyorum. Hadi dünyayı birlikte kurtaralım!',
            icon: CDN.result.mediumHigh,
            image: CDN.result.share.mobile.mediumHigh,
            height: '400px',
            backgroundColor: '#CD3232',
            card: CDN.result.sharedCards.hero4,
            cardTitle: CDN.result.sharedCards.hero4Title,
        },
        ['HIGH']: {
            id: 5,
            status: 'HIGH',
            title: 'Hızlı Moda Canavarı',
            content:
                'Selam Hızlı Moda Canavarı, cevaplarına göre sen tam bir moda tutkunusun. Beğendiğin her ürünü\n' +
                'almak istiyorsun. Görüyorum ki dolabında aynı modelden bir sürü farklı renkte, çok da kullanmadığın\n' +
                'kıyafetlerin var. İhtiyaçtan fazla alınan ürünlerin dünyamızda oluşturduğu yükü henüz\n' +
                'keşfetmemişsin, ama endişelenme. Çünkü sana henüz denemediğin ama modası asla geçmeyecek bir\n' +
                'öneriyle geldim.\n' +
                '<br/>' +
                '<br/>' +
                'Unutma, sadece bu yılın değil geleceğin trendi Döngüsel Moda! Hadi sana en yakın döngüsel\n' +
                'mağazalarımızla <a href="https://nivogo.com/dongusel-magazalarimiz" target="_blank" style="color:"inherit>Nivogo’yu</a> keşfetmeye hemen başla!',
            icon: CDN.result.high,
            image: CDN.result.share.mobile.high,
            height: '500px',
            backgroundColor: '#CD3232',
            card: CDN.result.sharedCards.hero5,
            cardTitle: CDN.result.sharedCards.hero5Title,
        },
    };

    const ShareButton = styled('button')(({ theme }) => ({
        '&:hover': {
            border: `4px solid ${theme.palette.success.main}`,
            fontWeight: 'normal',
            boxShadow: 'none',
            transition: '.3s',
        },
        position: 'absolute',
        bottom: '1.5rem',
        cursor: 'pointer',
        color: theme.palette.success.main,
        backgroundColor: '#E3AE4B',
        height: '2.5em',
        width: '30%',
        minWidth: 'fit-content',
        border: '1px solid #707070',
        borderRadius: '13px',
        fontWeight: 'bold',
        transition: '0.3s',
        marginTop: '1em',
    }));

    return (
        <>
            <Grid container item xs={12} mt={3}>
                <Grid container item xs={12} alignItems={'center'} justifyContent={'center'} display={'flex'}>
                    <Grid container item xs={12}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'} p={2}>
                                <img
                                    style={{ width: '90%', height: 'auto', maxWidth: '25rem' }}
                                    src={Status[state as keyof typeof Status]?.icon}
                                    alt={Status[state as keyof typeof Status]?.status}
                                />
                            </Grid>
                            <Grid item xs={12} p={3}>
                                <Typography variant={'subtitle2'}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: Status[state as keyof typeof Status]?.content,
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            xs={12}
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={Status[state as keyof typeof Status]?.image}
                                alt={'img'}
                                style={{
                                    width: '100vw',
                                    height: 'auto',
                                    marginBottom: '5rem',
                                }}
                            />
                            <ShareButton onClick={() => setShareDialogOpen(true)}>
                                <Typography
                                    variant={'subtitle1'}
                                    sx={{
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            fontWeight: 'normal',
                                        },
                                    }}
                                >
                                    Sonucunu Paylaş!
                                </Typography>
                            </ShareButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ShareDialog
                shareDialogOpen={shareDialogOpen}
                setShareDialogOpen={setShareDialogOpen}
                hero={Status[state as keyof typeof Status]}
            />
        </>
    );
};
export default MobileShare;
