import { Grid, Typography } from '@mui/material';
import FooterResult from './Footer';
import { FC } from 'react';
import CustomCards from './CustomCards';
import { CDN } from '../../constants/cdnUrls';

const ResultCharts: FC<{ result: any; matches: boolean }> = ({ result, matches }) => {
    return (
        <Grid
            container
            item
            xs={12}
            style={{
                backgroundColor: '#C8ECD8',
                color: '#FFF',
                backgroundImage: `url(${CDN.result.logoIcon})`,
                backgroundSize: 'auto 100vh',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'local',
                backgroundPosition: 'bottom',
            }}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            spacing={2}
            pt={3}
            pb={3}
        >
            <Grid item xs={12}>
                <Typography variant={'body1'}>Keşfetmeye Hazır Mısın?</Typography>
                <Typography variant={'subtitle2'} color={'#2B655A'}>
                    Moda ayak izi sonucunu öğrendiğine göre, sürdürülebilir,
                </Typography>
                <Typography variant={'subtitle2'} color={'#2B655A'}>
                    iyi bir dünya için günlük hayatında uygulayabileceğin öneriler için hazırsın
                </Typography>
            </Grid>
            <CustomCards matches={matches} />
            <FooterResult />
        </Grid>
    );
};
export default ResultCharts;
