import React, { FC } from 'react';
import StepButton from '../components/StepButton';
import Steps from '../components/Steps';
import Question from '../components/Question';
import { Grid, useMediaQuery } from '@mui/material';
import Range from '../components/Range';
import QuestionWrapper from '../components/QuestionWrapper';
import Information from '../components/Information';
import { InformationData } from '../constants/InformationData';
import MobileBottomWrapper from '../components/MobileBottomWrapper';
import { CDN } from '../constants/cdnUrls';

interface IPageProps {
    activeStep: number;

    setActiveStep(i: number): void;

    state: { shopping: number };
    setState: React.Dispatch<React.SetStateAction<{ shopping: number }>>;
    calculationEvent: {
        event: any;
        step: any;
        yanit: any;
        yuzde_dolap_kullanimi: any;
        alisveris_sikligi: any;
        kategori: any;
        yuzde_iade: any;
        yuzde_ikinci_el_tercih: any;
        yuzde_nivogo_tercih: any;
        camasir_makinesi_kullanim: any;
        yikama_sicaklik: any;
        kurutma_tipi: any;
        kiyafet_kullanim_suresi: any;
        kullanilmayan_giysi_degerlendirme: any;
    };

    setCalculationEvent: React.Dispatch<
        React.SetStateAction<{
            event: any;
            step: any;
            yanit: any;
            yuzde_dolap_kullanimi: string;
            alisveris_sikligi: any;
            kategori: any;
            yuzde_iade: any;
            yuzde_ikinci_el_tercih: any;
            yuzde_nivogo_tercih: any;
            camasir_makinesi_kullanim: any;
            yikama_sicaklik: any;
            kurutma_tipi: any;
            kiyafet_kullanim_suresi: any;
            kullanilmayan_giysi_degerlendirme: any;
        }>
    >;
}

const question = 'Yaptığın alışverişlerin yüzde kaçını iade ediyorsun?';

const Question4: FC<IPageProps> = ({
    activeStep,
    setActiveStep,
    state,
    setState,
    calculationEvent,
    setCalculationEvent,
}) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    return (
        <Grid container sx={{ height: 'calc(100vh - 65px)' }}>
            <Grid item sm={12} md={6} lg={6}>
                <img
                    alt={'white_4'}
                    src={matches ? CDN.question4.web.white : CDN.question4.mobile.white}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                              }
                    }
                />
                <img
                    alt={'dark_4'}
                    src={matches ? CDN.question4.web.dark : CDN.question4.mobile.dark}
                    style={
                        matches
                            ? {
                                  height: 'calc(100vh - 65px)',
                                  width: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: state.shopping / 100,
                              }
                            : {
                                  width: '100%',
                                  height: 'auto',
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  zIndex: '-99',
                                  opacity: state.shopping / 100,
                              }
                    }
                />
                {matches ? <Information content={InformationData['Question4']} /> : null}
            </Grid>
            <Grid
                item
                sm={12}
                md={12}
                lg={6}
                sx={
                    matches
                        ? null
                        : {
                              marginTop: '70%',
                              width: 'calc(100% - 60px)',
                              marginRight: '30px',
                              marginLeft: '30px',
                          }
                }
            >
                <Steps activeStep={activeStep} />
                <QuestionWrapper>
                    <Question text={question} />
                    <Range
                        rightTitle={`%${state.shopping}`}
                        min={0}
                        max={100}
                        value={state.shopping}
                        onChange={(e) => setState({ ...state, shopping: +e.target.value })}
                    />
                    <StepButton
                        increase={() => {
                            setActiveStep(5);
                            setCalculationEvent({
                                ...calculationEvent,
                                step: 'soru4',
                                yuzde_iade: `%${state.shopping}`,
                                yanit: `%${state.shopping}`,
                            });
                        }}
                        decrease={() => setActiveStep(3)}
                    />
                </QuestionWrapper>
            </Grid>
            {!matches && <MobileBottomWrapper question={'Question4'} />}
        </Grid>
    );
};

export default Question4;

