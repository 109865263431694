import { FC } from 'react';
import LandingButton from '../../components/LandingButton';
import { Grid, Typography } from '@mui/material';
import Footer from '../../components/Footer';
import { CDN } from '../../constants/cdnUrls';

interface IPageProps {
    setActiveStep(i: number): void;
}

const MobileLanding: FC<IPageProps> = ({ setActiveStep }) => {
    return (
        <>
            <Grid
                container
                sx={{
                    minHeight: '500px',
                    boxSizing: 'border-box',
                }}
            >
                <Grid item xs={12}>
                    <img src={CDN.landing.mobile} alt={'Landing'} style={{ width: '100%', height: 'auto' }} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        position: 'absolute',
                        top: '85vw',
                    }}
                >
                    <Grid pl={2} pr={2}>
                        <img
                            src={CDN.landing.text}
                            alt={'LandingText'}
                            style={{ maxWidth: '500px', width: '80%', height: 'auto' }}
                        />

                        <Typography
                            variant={'subtitle2'}
                            sx={{ color: '#00675A', textAlign: 'center', marginTop: '20px' }}
                        >
                            Moda endüstrisinin yeryüzündeki sera gazı salımının %10’undan sorumlu olduğunu eğer hiçbir
                            değişiklik yapılmazsa bu oranın 2050 yılında %26’ya yükseleceğini biliyor muydun? Bu yaşamak
                            için 2,8 adet dünyaya ihtiyacımız olacak anlamına geliyor. Dolabındaki ürünlerin ve moda
                            alışkanlıklarının dünyamıza etkisini Nivogo’yla keşfetmeye şimdi başla, dolabın ve dünyamız
                            için gerçek fırsatları kaçırma!
                        </Typography>
                        <LandingButton title={'Moda Ayak İzini Hesapla'} onClick={() => setActiveStep(1)} />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default MobileLanding;
