import React, { FC, useEffect, useState } from 'react';
import Foot from '../components/Result/Foot';
import { Grid, useMediaQuery } from '@mui/material';
import Avarage from '../components/Result/Avarage';
import ResultShare from '../components/Result/ResultShare';
import ResultChartPage from '../components/Result/ChartPage';
import Airplane from '../components/Result/Airplane';
import Emojis from '../components/Result/Emojis';
import MobileCarousel from '../components/Result/Mobile/MobileCarousel';
import MobileShare from '../components/Result/Mobile/MobileShare';
import MobileEmojis from '../components/Result/Mobile/MobileEmojis';
import { IResult } from '../App';

interface IResultPage {
    surveyId: string;
    result: IResult;

    calculationResult: {
        event: any;
        deger: any;
        karbon_emisyonu: any;
    };

    setCalculationResult: React.Dispatch<
        React.SetStateAction<{
            event: any;
            deger: any;
            karbon_emisyonu: any;
        }>
    >;
}

export enum State {
    LOW = 'LOW',
    LOW_MEDIUM = 'LOW_MEDIUM',
    MEDIUM = 'MEDIUM',
    MEDIUM_HIGH = 'MEDIUM_HIGH',
    HIGH = 'HIGH',
}

const Result: FC<IResultPage> = ({ result, surveyId, setCalculationResult, calculationResult }) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    const [state, setState] = useState(State.LOW);
    const avarage = 270;

    useEffect(() => {
        if (result.footPrint >= 0 && result.footPrint < 110) {
            setState(State.LOW);
            setCalculationResult({
                ...calculationResult,
                deger: State.LOW,
                karbon_emisyonu: result.footPrint,
            });
        } else if (result.footPrint >= 110 && result.footPrint < 220) {
            setState(State.LOW_MEDIUM);
            setCalculationResult({
                ...calculationResult,
                deger: State.LOW_MEDIUM,
                karbon_emisyonu: result.footPrint,
            });
        } else if (result.footPrint >= 220 && result.footPrint < 320) {
            setState(State.MEDIUM);
            setCalculationResult({
                ...calculationResult,
                deger: State.MEDIUM,
                karbon_emisyonu: result.footPrint,
            });
        } else if (result.footPrint >= 320 && result.footPrint < 430) {
            setState(State.MEDIUM_HIGH);
            setCalculationResult({
                ...calculationResult,
                deger: State.MEDIUM_HIGH,
                karbon_emisyonu: result.footPrint,
            });
        } else if (result.footPrint >= 430) {
            setState(State.HIGH);
            setCalculationResult({
                ...calculationResult,
                deger: State.HIGH,
                karbon_emisyonu: result.footPrint,
            });
        } else {
            setState(State.LOW);
            setCalculationResult({
                ...calculationResult,
                deger: State.LOW,
                karbon_emisyonu: result.footPrint,
            });
        }
    }, []);

    return (
        <Grid container>
            <Foot result={result} state={state} matches={matches} />

            {matches ? (
                <>
                    <Avarage result={result} state={state} matches={matches} avarage={avarage} />
                    <ResultShare result={result} state={state} matches={matches} />
                    <Airplane result={result} matches={matches} />
                    <Emojis result={result} surveyId={surveyId} matches={matches} />
                </>
            ) : (
                <>
                    <MobileShare result={result} state={state} matches={matches} />
                    <MobileCarousel result={result} state={state} matches={matches} avarage={avarage} />
                    <MobileEmojis result={result} surveyId={surveyId} matches={matches} />
                </>
            )}
            <ResultChartPage result={result} matches={matches} />
        </Grid>
    );
};

export default Result;

