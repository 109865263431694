import { Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import ShareCardDialog from './ShareCardDialog';
import { CDN } from '../../constants/cdnUrls';

const CardsWrapper = styled('div')<{ matches: boolean }>(({ matches, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 'content-fit',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
}));

const CustomCard = styled('div')<{ matches: boolean; cardMatches: boolean }>(({ matches, theme, cardMatches }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '20px',
    '&:nth-child(1)': matches
        ? {
              maxWidth: '25rem',
              width: '25vh',
              height: 'auto',
          }
        : { display: 'none' },
    '&:nth-child(2)': matches
        ? {
              maxWidth: '30rem',
              width: '30vh',
              height: 'auto',
          }
        : cardMatches
        ? {
              width: '30vh',
              height: 'auto',
          }
        : { display: 'none' },
    '&:nth-child(3)': matches
        ? {
              maxWidth: '45rem',
              width: '45vh',
              height: 'auto',
          }
        : cardMatches
        ? {
              width: '40vh',
              height: 'auto',
          }
        : {
              marginRight: 0,
              width: '30vh',
              height: 'auto',
          },
    '&:nth-child(4)': matches
        ? {
              maxWidth: '30rem',
              width: '30vh',
              height: 'auto',
          }
        : cardMatches
        ? {
              marginRight: 0,
              width: '30vh',
              height: 'auto',
          }
        : { display: 'none' },
    '&:nth-child(5)': matches
        ? {
              marginRight: 0,
              maxWidth: '25rem',
              width: '25vh',
              height: 'auto',
          }
        : { display: 'none' },
    '&:nth-child(6)': { display: 'none' },
    '&:nth-child(7)': { display: 'none' },
    '&:nth-child(8)': { display: 'none' },
    '&:nth-child(9)': { display: 'none' },
    '&:nth-child(10)': { display: 'none' },
}));

const CustomCards: FC<{ matches: boolean }> = ({ matches }) => {
    const cardsMatches = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
    const [shareCardDialog, setShareCardDialog] = useState<boolean>(false);

    const [cards, setCards] = useState<Array<any>>([
        {
            id: 1,
            png: CDN.result.cards.card1,
            title: 'Harekete Geç',
        },
        {
            id: 2,
            png: CDN.result.cards.card2,
            title: 'Harekete Geç',
        },
        {
            id: 3,
            png: CDN.result.cards.card3,
            title: 'Uygula',
        },
        {
            id: 4,
            png: CDN.result.cards.card4,
            title: 'Dene',
        },
        {
            id: 5,
            png: CDN.result.cards.card5,
            title: 'Uygula',
        },
        {
            id: 6,
            png: CDN.result.cards.card6,
            title: 'Keşfet',
        },
        {
            id: 7,
            png: CDN.result.cards.card7,
            title: 'Dene',
        },
        {
            id: 8,
            png: CDN.result.cards.card8,
            title: 'Keşfet',
        },
        {
            id: 9,
            png: CDN.result.cards.card9,
            title: 'Yeniden Tasarla',
        },
        {
            id: 10,
            png: CDN.result.cards.card10,
            title: 'Yeniden Tasarla',
        },
    ]);
    const prevHandler = () => {
        setCards([cards[9], cards[0], cards[1], cards[2], cards[3], cards[4], cards[5], cards[6], cards[7], cards[8]]);
    };
    const nextHandler = () => {
        setCards([cards[1], cards[2], cards[3], cards[4], cards[5], cards[6], cards[7], cards[8], cards[9], cards[0]]);
    };

    const AdviceShareButton = styled('button')(({ theme }) => ({
        '&:hover': {
            border: `4px solid #3f7469`,
            fontWeight: 'normal',
            boxShadow: 'none',
            transition: '.3s',
        },
        cursor: 'pointer',
        backgroundColor: '#00675A',
        height: '3.938em',
        width: '14em',
        border: '1px solid #707070',
        borderRadius: '13px',
        fontWeight: 'bold',
        transition: '0.3s',
        marginTop: '1em',
    }));

    return (
        <>
            <CardsWrapper matches={matches}>
                {cards?.map((card, index) => (
                    <CustomCard matches={matches} cardMatches={cardsMatches} key={card.id}>
                        {!shareCardDialog && index == 2 && (
                            <img
                                src={CDN.result.sharedCards.leftArrow}
                                alt={'left'}
                                onClick={prevHandler}
                                style={{ width: '3.5em', height: '4em', zIndex: 9999 }}
                            />
                        )}
                        <img
                            src={card?.png}
                            style={{
                                width: '100%',
                                //width: '25vh',
                                height: 'auto',
                                paddingRight: '1em',
                                paddingLeft: '1em',
                            }}
                        />
                        {!shareCardDialog && index == 2 && (
                            <img
                                src={CDN.result.sharedCards.rightArrow}
                                alt={'right'}
                                onClick={nextHandler}
                                style={{ width: '3.5em', height: '4em', zIndex: 9999 }}
                            />
                        )}
                    </CustomCard>
                ))}
            </CardsWrapper>
            <Grid item xs={12}>
                <AdviceShareButton onClick={() => setShareCardDialog(true)}>
                    <Typography
                        variant={'subtitle1'}
                        color={'#FFF'}
                        sx={{
                            fontWeight: 'bold',
                            '&:hover': {
                                fontWeight: 'normal',
                            },
                        }}
                    >
                        Tavsiyeyi Paylaş
                    </Typography>
                </AdviceShareButton>
            </Grid>
            <ShareCardDialog
                shareCardDialogOpen={shareCardDialog}
                setShareCardDialogOpen={setShareCardDialog}
                hero={cards[2]}
            />
        </>
    );
};
export default CustomCards;
