import { styled, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';

interface ILandingButtonProps {
    title: string;
    onClick?: () => void;
}

const LandingButton: FC<ILandingButtonProps> = ({ title = '', onClick }) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

    const LandingButtonWrapper = styled('button')(({ theme }) => ({
        '&:hover': {
            border: `4px solid ${theme.palette.success.main}`,
            fontWeight: 'normal',
            boxShadow: 'none',
            transition: '.3s',
        },
        cursor: 'pointer',
        color: theme.palette.success.main,
        backgroundColor: '#E3AE4B',
        height: '3.938em',
        border: '1px solid #707070',
        borderRadius: '13px',
        fontWeight: 'bold',
        transition: '0.3s',
        marginTop: '2em',
        marginBottom: '1em',
        [theme.breakpoints.up('xs')]: {
            width: '90%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '80%',
        },
        [theme.breakpoints.up('md')]: {
            width: '65%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '55%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '45%',
        },
    }));
    return (
        <LandingButtonWrapper onClick={onClick}>
            <Typography
                variant={'button'}
                fontWeight={'bold'}
                sx={{
                    '&:hover': {
                        fontWeight: 'normal',
                    },
                }}
            >
                {title}
            </Typography>
        </LandingButtonWrapper>
    );
};

export default LandingButton;
