export const AdviceCards = [
    {
        id: 1,
        title: 'Harekete Geç',
        content: 'Kullanmadığın bir ürününü Nivogo’yla hayata döndür!',
    },
    {
        id: 2,
        title: 'Harekete Geç',
        content: 'Bir sonraki yıkamanda kurutma makinasını es geç, giysilerini ipe asarak kurut!',
    },
    {
        id: 3,
        title: 'Uygula',
        content: 'Renkli ve siyah giysilerinde solmayı engellemek için hassas programda yıkamayı tercih et.',
    },
    {
        id: 4,
        title: 'Dene',
        content: 'Alışverişe çıkmadan önce fazlasını almamak için, ihtiyaçlarının bir listesini yap.',
    },
    {
        id: 5,
        title: 'Uygula',
        content: 'Giysilerinin ömrünü uzatmak için 30 derecenin altında yıkamayı dene!',
    },
    {
        id: 6,
        title: 'Keşfet',
        content: 'Nivogo’nun yenilenmiş ürünlerini keşfet!',
    },
    {
        id: 7,
        title: 'Dene',
        content: 'Yeni ürün almak yerine en yakın arkadaşınla değiş tokuş yap.',
    },
    {
        id: 8,
        title: 'Keşfet',
        content: 'Özel bir davetin mi var? Giyeceğin kıyafeti bu kez kiralamayı dene!',
    },
    {
        id: 9,
        title: 'Yeniden Tasarla',
        content: 'Söküğü veya yırtığı olan kıyafetlerini atmak yerine onararak yeniden hayata döndür!',
    },
    {
        id: 10,
        title: 'Yeniden Tasarla',
        content:
            'Nivogo’nun kişiselleştirme atölyelerinden birine katıl, giymediğin bir ayakkabını sanatla hayata döndür.',
    },
];
