import { Button, Grid, styled, Typography, Link } from '@mui/material';
import { FC } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PoweredBy from './PoweredBy';

interface IFooterProps {}

const FooterWrapper = styled('footer')(({ theme }) => ({
    width: '100%',
    minHeight: '70px',
    height: 'min-content',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px 0 20px',
}));

const Footer: FC<IFooterProps> = () => {
    return (
        <FooterWrapper>
            <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid xs={12} display={'flex'} justifyContent={'center'}>
                        <Typography variant={'subtitle2'} textAlign={'start'}>
                            Nivogo Moda Ayak İzi Hesaplayıcısı ile ulaşılan karbon emisyonu değerleri Metsims
                            Sustainability Consulting desteğiyle hesaplanmıştır.
                        </Typography>
                    </Grid>

                    <Grid xs={12} display={'flex'} justifyContent={'space-between'}>
                        <Grid></Grid>
                        <Grid>
                            <PoweredBy />
                        </Grid>
                        <Grid>
                            <Link
                                href={'https://www.linkedin.com/company/nivogo/?originalSubdomain=tr'}
                                target={'_blank'}
                                color={'white'}
                            >
                                <LinkedInIcon fontWeight={'32px'} />
                            </Link>
                            <Link href={'https://www.instagram.com/nivogocom/'} target={'_blank'} color={'white'}>
                                <InstagramIcon fontWeight={'32px'} />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FooterWrapper>
    );
};

export default Footer;
